import {
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { RouteComponentProps } from "react-router";
import Logo from "../../components/Logo";
import "./About.scss";
import LoginButtons from "../../components/LoginButtons";
import { useSelector } from "react-redux";
import { RootReducerType } from "../../types/Reducers.d";

const About: React.FC<RouteComponentProps<{ name: string }>> = ({ match }) => {
  const version = useSelector((s: RootReducerType) => s.config.app.version);
  return (
    <IonPage>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle>Sobre 5R INDEX</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div data-comp-about={true} className="content ion-padding">
          <div className="logo">
            <Logo />
          </div>
          <h2>O que é?</h2>
          <p>
            O <strong>Sistema 5R-INDEX</strong> é a integração de uma nova metodologia, uma nova métrica e um novo indicador para <strong>exame</strong> e <strong>aferição</strong> do nível de <strong>transparência organizacional</strong> – de uma empresa, órgão público ou entidade do terceiro setor.
          </p>

          <p>
            Sob o viés da Comunicação Organizacional é, também, um guia de melhores práticas para a Governança Corporativa no sentido da <strong>mitigação de riscos de crises reputacionais e de imagem pública</strong>.
          </p>

          <h2>5 instâncias/demandas</h2>
          <p>A organização inteira, sob os seus aspectos administrativo, mercadológico e operacional, é submetida a um exame – uma <strong>Auditoria Funcional da Comunicação Organizacional (AFCO)</strong> – composta por 210 quesitos divididos em 5 instâncias, 10 estratégias e 20 táticas.</p>

          <p>Tais instâncias constituem demandas bem conhecidas pelos gestores e são comuns a todo e qualquer ramo de atividade ou porte organizacional: <strong>Reconhecimento</strong> no meio social, <strong>Relacionamento</strong> com públicos-chave, <strong>Relevância</strong> em seu segmento de atuação, <strong>Reputação</strong> administrada e <strong>Resiliência</strong> institucional.</p>

          <p>O resultado do exame soma-se aos resultados das respectivas auditorias nos campos do <strong>Direito</strong> (Leis Civis atinentes a cada setor, LGPD, Lei de Acesso à Informação, por exemplo) e da <strong>Contabilidade</strong> (Regulamentos CPC, CVM, IFRS), campos estes também envolvidos na questão da <strong>transparência</strong> – que, de passiva, torna-se <strong>ativa</strong>.</p>

          <h2>Como tornar-se um auditor-consultor da Transparência Ativa?</h2>
          <h3>Formação/Habilitação</h3>
          <p>
          É requisito uma formação concluída em Administração, Comunicação, Direito, Marketing, Negócios ou áreas afins. Pós-graduação é algo desejável mas não mandatório.
          </p>

          <p>
          Experiência de mercado: é ideal que o candidato a auditor-consultor tenha experiência de, pelo menos,10 anos, com 5 anos preferencialmente em funções gerenciais ou docentes.
          </p>

          <h3>Certificação/Credenciamento (PF)</h3>

          <p>Obtém-se a certificação AFCO a partir da conclusão – com aprovação – no curso preparatório em Auditoria Funcional da Comunicação Organizacional (40 horas-aula).</p>

          <p>Obtém-se o credenciamento a partir (1) da associação ao Observatório da Comunicação Institucional e (2) da participação assistida em pelo menos um “job” AFCO.</p>

          <h3>Acreditação (PJ)</h3>

          <p>
          No caso das Pessoas Jurídicas, além dos passos individuais anteriores, deverá estabelecer-se um acordo de parceria entre a organização e a Sociedade Educativa Observatório da Comunicação Institucional.
          </p>
          <p>
          No caso de obtenção - pós-exame - de um “score” geral final entre 90 e 100 pontos (numa escala que vai de zero a 100 pontos), a PJ parceira pode requisitar o Certificado de Organização Transparente conforme o 5R-INDEX para seu cliente.
          </p>

          <LoginButtons />
        </div>

        <IonFooter>
          <IonItem lines="none" style={{ fontSize: ".7rem" }}>
            versão {version}
          </IonItem>
        </IonFooter>
      </IonContent>
    </IonPage>
  );
};

export default About;
