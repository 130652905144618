import {
  IonCard,
  IonCardContent,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import React, {useEffect, useMemo, useState} from 'react';
import {RouteComponentProps} from 'react-router';

import NavBar from '../../components/NavBar';
import StatusHeader from '../../components/Assessment/StatusHeader';
import AxisNav from '../../components/Assessment/AxisNav';
import QuestionsList from '../../components/Assessment/QuestionsList';
import useAssessment from '../../components/Assessment/hooks/useAssesment';
import {Assessment} from '../../types/Assessments.d';
import './AssessmentsStatus.scss';
import {AssessmentPresenter} from '../../components/Assessment/AssessmentPresenter';
import {updateScore} from '../../store/actions/progressActions';
import {useDispatch} from 'react-redux';
import Spinner from '../../components/UI/Spinner/Spinner';

interface AssessmentsStatusProps {
}

const AssessmentsStatus = (props: RouteComponentProps<{ axis: string; assessmentId: string }> & AssessmentsStatusProps) => {

  const disp = useDispatch();
  const [axis, setAxis] = useState('');
  const [assId, setAssId] = useState('');
  const [assessmentPre, setAssessmentPre] = useState<AssessmentPresenter | null>(null);
  const assessment: Assessment = useAssessment(props.match.params.assessmentId, false);

  useEffect(() => {
    if (assessment) disp(updateScore(assessment));
    setAssessmentPre(new AssessmentPresenter(assessment));
  }, [assessment]);

  useEffect(() => {
    let p: any = props.match.params;
    p.axis && setAxis(p.axis);
    p.assessmentId && setAssId(p.assessmentId);
  }, [props.match.params]);


  const memoStatusHeader = useMemo(() => {
    if (assessment && assessmentPre) {
      return <StatusHeader assessment={assessmentPre} entity={assessment.entity}/>;
    }
  }, [assessment, assessmentPre]);


  return (
    <IonPage>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle>Status</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div data-comp-status={true} className="content">
          {memoStatusHeader}
          <AxisNav assessmentId={assId} currentAxis={axis}/>
          <IonCard className="card__first">
            <IonCardContent>
              {assessmentPre
                ? <QuestionsList assessment={assessmentPre} axis={axis}/>
                : (<Spinner/>)}
            </IonCardContent>
          </IonCard>

        </div>
      </IonContent>
      <IonFooter>
        <NavBar/>
      </IonFooter>
    </IonPage>
  );
};

export default AssessmentsStatus;
