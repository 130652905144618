import {useFirestoreConnect} from 'react-redux-firebase';
import {useSelector} from 'react-redux';
import {RootReducerType} from '../../../types/Reducers.d';
import useAuth from '../../User/hooks/useAuth';


function useMyEntities(){
  useFirestoreConnect('entities');
  const [isLogged, profile, auth] = useAuth();
  let entities = useSelector((state: RootReducerType) => state.firestore.ordered.entities)

  if(entities){
    entities = entities.filter((entity)=> entity.auditor === auth.uid)
  }

  return entities;
}

export default useMyEntities;
