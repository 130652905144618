import {IonContent, IonFooter, IonHeader, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import './Auditors.scss';
import NavBar from '../../components/NavBar';

const Auditors: React.FC<RouteComponentProps<{ name: string; }>> = ({ match }) => {
  return (
    <IonPage>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle>Auditores</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div data-comp-auditors={true} className="content ion-padding">
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam commodi consequatur deleniti deserunt enim libero magnam nam natus nihil vitae. Aspernatur debitis earum expedita ipsa natus necessitatibus pariatur reprehenderit vero.</p>
        </div>
      </IonContent>
      <IonFooter>
        <NavBar/>
      </IonFooter>
    </IonPage>
  );
};

export default Auditors;
