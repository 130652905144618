/* eslint-disable no-throw-literal */
import React, {useEffect, useState} from 'react';
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonList,
  IonModal,
  IonRow,
  IonTitle, IonToast,
  IonToolbar
} from '@ionic/react';
import {Field, FormErrors, InjectedFormProps, reduxForm} from 'redux-form';
import {useDispatch} from 'react-redux';
import {Dispatch} from 'redux';
import isNull from 'lodash/isNull';


import {Entity, EntityFormProps} from '../../types/Entities.d';
import {addUpdateEntity, loadEntity} from '../../store/actions/entityActions';
import Text from '../UI/FormInputs/Text';
import useAuth from '../User/hooks/useAuth';
import Loading from '../UI/Loading';
import {createAssessment} from '../../store/actions/assessmentActions';
import {Error} from '../../types/Reducers.d';
import Select from '../UI/FormInputs/Select';
import { getEstadosBrasileiros, getTiposSociedade, getSegmentos, getPortes } from '../../support/helpers';


type MyFormProps = InjectedFormProps<{}, EntityFormProps, FormErrors> & EntityFormProps;

const EntityForm: React.FC<MyFormProps> = (props: MyFormProps) => {
  const disp = useDispatch();
  const [formType, setFormType] = useState<'creation' | 'edition'>('creation');
  const [isLogged, profile] = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [toast, setToast] = useState<boolean>(false);
  const [toastMsg, setToastMsg] = useState<string>('');

  useEffect(() => {
    disp(loadEntity(props.selected));
  }, [props.selected]);

  function willPresent() {
    setFormType(isNull(props.selected) ? 'creation' : 'edition');
  }

  function dismissForm() {
    props.dismiss(formType === 'creation');
    props.reset();
  }

  function onSubmit(values: any) {

    setLoading(true);

    let data = {
      id: (props.selected && props.selected.id) ? props.selected.id : '',
      name: values.name,
      cnpj: values.cnpj,
      ownerName: values.ownerName,
      ownerEmail: values.ownerEmail,
      assessmentId: [],
      auditor: profile.id,
      city: values.city,
      state: values.state,
      size: values.size,
      type: values.type,
      segment: values.segment,
    };

    disp(addUpdateEntity(data, (entityId: boolean | string, err?: Error) => {
      // show
      console.log('created?', entityId);
      // erro na criação/atualização
      if (!entityId) {
        setToastMsg(err?.message || '');
        setToast(true);
      } else {
        if (formType === 'creation') {
          disp(createAssessment({...data, id: String(entityId)}));
        }
        dismissForm();
      }
      setLoading(false);
    }));
  }

  const Toast = ({msg}: { msg: string }) => {
    return (
      <IonToast
        isOpen={toast}
        onDidDismiss={() => setToast(false)}
        message={msg}
        position="top"
        color="secondary"
        duration={3000}
      />
    );
  };

  return (
    <IonModal isOpen={props.isOpen} swipeToClose={true}
              onWillPresent={willPresent}
              onDidDismiss={dismissForm}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{formType === 'creation'
            ? 'Nova entidade' : 'Editar entidade'}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => props.dismiss(formType === 'creation')}
                       color="secondary">cancelar</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <Loading isOpen={loading}/>
        <Toast msg={toastMsg}/>
        <form onSubmit={props.handleSubmit(onSubmit)}>
          <IonList style={{background: 'transparent'}}>
            {formType === 'creation'
            && <small>* O CNPJ não poderá ser editado.</small>}
            <Field name="cnpj" type="text" component={Text} disabled={props.selected?.id.length}
                   label="CNPJ" placeholder="00.000.000/0000-00" mask="99.999.999/9999-99"/>
            <Field name="name" type="text" component={Text}
                   label="Razão Social"/>
            <Field name="ownerName" type="text" component={Text}
                   label="Nome do responsável"/>
            <Field name="ownerEmail" type="text" component={Text}
                   label="E-mail do responsável"/>
            <IonRow>
              <IonCol>
                <Field name="city" type="text" component={Text} label="Cidade"/>
              </IonCol>
              <IonCol>
                <Field name="state" type="text" component={Select}
                  options={getEstadosBrasileiros()} label="Estado"/>
              </IonCol>
            </IonRow>
            <Field name="size" type="text" component={Select} options={getPortes()}
                   label="Porte"/>
            <Field name="type" type="text" component={Select} options={getTiposSociedade()}
                   label="Tipo de sociedade"/>
            <Field name="segment" type="text" component={Select} options={getSegmentos()}
                   label="Segmento"/>
          </IonList>
          <div className="ion-padding-vertical">
            <IonButton type="submit" className="" expand="block">SALVAR</IonButton>
          </div>
        </form>
      </IonContent>

    </IonModal>
  );
};

/**
 * Validação síncrona não está sendo usado por causa de um bug
 * @param values
 * @param props

 const validate = (values: Entity, props: EntityFormProps & InjectedFormProps<{}, EntityFormProps, {}>): { [P in keyof {}]?: React.ReactElement | string } & ErrorOther => {
  const errors: FormErrors<Entity | null> = {};

  if (!values.cnpj) {
    errors.cnpj = 'CNPJ é obrigatório';
  }
  if (!values.name) {
    errors.name = 'Razão Social é obrigatório';
  }
  if (!values.ownerName) {
    errors.ownerName = 'Nome do responsável é obrigatório';
  }
  if (!values.ownerEmail) {
    errors.ownerEmail = 'E-mail do responsável é obrigatório';
  }

  return errors;
};
 */

const asyncValidate = async (values: Entity, dispatch: Dispatch, props: EntityFormProps, fieldString: string) => {
  // console.log(fieldString, values);
  if (!fieldString && !values.cnpj) {
    throw {cnpj: `CNPJ é obrigatório`};
  }
  // 87.897.878/9776-57
  if(values.cnpj.replace(/\D/g, '').length != 14){
    throw {cnpj: `CNPJ inválido`};
  }
  if (!fieldString && !values.name) {
    throw {name: `Razão Social é obrigatório`};
  }
  if (!fieldString && !values.ownerName) {
    throw {ownerName: `Nome do responsável é obrigatório`};
  }
  if (!fieldString &&
    (!values.ownerEmail || values.ownerEmail.indexOf('@') === -1)) {
    throw {ownerEmail: `E-mail do responsável é obrigatório`};
  }
  if (!fieldString && !values.city) {
    throw {city: `Cidade é obrigatório`};
  }
  if (!fieldString && !values.state) {
    throw {state: `Estado é obrigatório`};
  }
  if (!fieldString && !values.size) {
    throw {size: `Porte é obrigatório`};
  }
  if (!fieldString && !values.type) {
    throw {type: `Tipo de Sociedade é obrigatório`};
  }
  if (!fieldString && !values.segment) {
    throw {segment: `Segmento é obrigatório`};
  }

};


export default reduxForm<{}, EntityFormProps, FormErrors>({
  form: 'entity',
  enableReinitialize: true,
  // validate,
  asyncValidate,
  asyncBlurFields: [],
  persistentSubmitErrors: true
})(EntityForm);
