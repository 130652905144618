import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import {connectedRouterRedirect} from 'redux-auth-wrapper/history4/redirect';
import {createBrowserHistory} from 'history';
import LoadingScreen from '../../SplashScreen';
import {Action, Dispatch} from 'redux';
import {returnDownBack} from 'ionicons/icons';
import {RootReducerType} from '../../../types/Reducers.d';


const locationHelper = locationHelperBuilder({});
const browserHistory = createBrowserHistory();

export const AllowIfAuthenticated = connectedRouterRedirect({
  wrapperDisplayName: 'AllowIfAuthenticated',
  AuthenticatingComponent: LoadingScreen,
  allowRedirectBack: true,
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || '/login',
  authenticatingSelector: (state: RootReducerType): boolean => {
    return !state.firebase.auth.isLoaded || state.firebase.isInitializing === true;
  },
  authenticatedSelector: ({firebase: {auth}}) =>
    auth.isLoaded && !auth.isEmpty,
  redirectAction: (newLoc: {}): Action | any => (dispatch: Dispatch) => {
    browserHistory.replace(newLoc); // or routerActions.replace
    dispatch({type: 'UNAUTHED_REDIRECT'});
  },
});
