import {CONFIG_ACTION_TYPE} from '../actions/configActions';
import {ReducerAction} from '../../types/Reducers.d';

const initState = {
  app: {version: '1.2.0'}
};

const ConfigReducer = (state: {} = initState, action: ReducerAction<any>) => {

  if(action.type === CONFIG_ACTION_TYPE.LOAD){
    return {...state, ...action.payload}
  }

  return state;
};

export default ConfigReducer;
