import React from "react";
import { WrappedFieldProps } from "redux-form";
import { IonInput, IonItem, IonLabel, IonSelect, IonSelectOption, IonText } from "@ionic/react";

interface CustomProps {
  mask?: any;
  options: {value: string; label: string}[]
}

type TextProps = WrappedFieldProps & CustomProps & any;

const Select: React.FC<TextProps> = (props: TextProps) => {
  const { input, meta, type, label, mask, options, disabled } = props;
// console.log({props});

  function maskprops() {
    if (typeof mask === "undefined") return null;
    if (typeof mask === "string") return { mask };
    if (typeof mask === "object") return mask;
  }

  return (
    <IonItem className="ion-no-padding">
      <IonLabel position="stacked">{label}</IonLabel>

        <IonSelect {...input} value={input.value} okText="OK" cancelText="cancelar"
          onIonChange={e => {
            input.onChange(e.detail.value)
          }}
          disabled={disabled}>{options.map((o:any) => { 
            return <IonSelectOption key={o.value} value={o.value}>{o.label}</IonSelectOption>
          })}
        </IonSelect>


      {meta.touched && meta.error && (
        <IonText className="form-error">{meta.error}</IonText>
      )}
    </IonItem>
  );
};

export default Select;
