import {ReducerAction} from '../../types/Reducers.d';
import {AUTH_ACTION_TYPE} from '../actions/authActions';


const initState = {};

const AuthReducer = (state: {} = initState, action: ReducerAction<any>) => {

  if (action.type === AUTH_ACTION_TYPE.CODE_VALIDATE) {
    return {
      ...state,
      code: action.payload
    };
  }
  else if (action.type === AUTH_ACTION_TYPE.LOGIN){
    // validate user code
    return  {
      ...state
    }
  }

  return state;
};

export default AuthReducer;
