import {
  IonCard,
  IonCardContent,
  IonContent, IonFab, IonFabButton,
  IonFooter,
  IonHeader, IonIcon, IonItem, IonLabel,
  IonPage,
  IonTitle,
  IonToast,
  IonToolbar,
  isPlatform
} from '@ionic/react';
import React, { useState } from 'react';
import {RouteComponentProps, useHistory} from 'react-router';
import {listCircleOutline, mailOutline, downloadOutline} from 'ionicons/icons';
// import {SocialSharing} from '@ionic-native/social-sharing';
import {Plugins} from '@capacitor/core';


import NavBar from '../../components/NavBar';
import useAssessment from '../../components/Assessment/hooks/useAssesment';
import Spinner from '../../components/UI/Spinner/Spinner';
import './Score.scss';
import Logo from '../../components/Logo';
import {AssessmentPresenter} from '../../components/Assessment/AssessmentPresenter';
import Api from '../../support/api';
import useAuth from '../../components/User/hooks/useAuth';
import { useDispatch } from 'react-redux';
import { syncAssessment } from '../../store/actions/assessmentActions';
import Loading from '../../components/UI/Loading';

interface ScoreProps {
}

const Score = ({match: {params: {assessmentId}}}: ScoreProps & RouteComponentProps<{ assessmentId: string }>) => {

  const history = useHistory();
  const disp = useDispatch();
  const [isLogged, profile, auth] = useAuth()
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const assessment = useAssessment(assessmentId, false);
  const assessmentPre = new AssessmentPresenter(assessment);

  const Toast = () => {
    return (
      <IonToast
        isOpen={!!error}
        onDidDismiss={() => setError('')}
        message={error}
        position="top"
        color="danger"
        duration={3000}
      />
    );
  };

  async function shareScore() {

    let url = assessmentPre?.score?.url;
    let title = `Compartilhar`;
    let text = `${assessmentPre.entity.name} - Índice de Transparência Ativa`;
    let body = `\r\nVisite o link abaixo para visualizar o relatório:
${Api.getWebUrl()}/score/${assessmentPre.id}

---
5R INDEX
www.5r-index.com.br`;

    // "ios" | "ipad" | "iphone" | "android" | "phablet" | "tablet" | "cordova" | "capacitor" | "electron" | "pwa" | "mobile" | "mobileweb" | "desktop" | "hybrid"
    if (isPlatform('mobileweb')) {
      let shareRet = await Plugins.Share.share({
        title: text, text: text, url: url, dialogTitle: title
      });
      console.log(shareRet);
    } else if (isPlatform('desktop')) {

      window.open(`mailto:?subject=${text}&body=${encodeURIComponent(body)}`);
    }

    // SocialSharing.shareWithOptions(options).then((result: any)=>{
    //   console.log("Share completed? " + result.completed);
    //   // On Android apps mostly return false even while it's true
    //   console.log("Shared to app: " + result.app);
    //   // On Android result.app since plugin version 5.4.0 this is no longer empty.
    //   // On iOS it's empty when sharing is cancelled (result.completed=false)
    // }, (msg)=>{
    //   alert(msg)
    //   console.log("Sharing failed with message: " + msg);
    // });
  }

  function reportRMI(){
    setLoading(true);
    // todo resync
    disp(syncAssessment(assessmentPre.id, (resp)=>{
      console.info({resp});
      setLoading(false);
      if(resp.status === 200){
        // open
        window.open(`${Api.getWebUrl()}/report/${assessmentPre.id}/${profile.id}`);
      } else {
        setError(resp?.data?.message || 'Erro ao gerar relatório');
      }
    }));

  }


  return (
    <IonPage>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle>Score</IonTitle>
          </IonToolbar>
        </IonHeader>
        <Toast />
        <Loading isOpen={loading}/>
        {!assessmentPre.loaded() ? (<Spinner/>) : (
          <div data-comp-score={true} className="content">
            <IonCard className="card__first">
              <IonCardContent>
                <strong>{assessmentPre.entity.name}</strong>
              </IonCardContent>

            </IonCard>
            <div className="middle ion-padding">
              <div className="score-splash">
                {/* Botão para adicionar entidade */}
                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                  <IonFabButton onClick={() => {
                    history.push(`/scoredetails/${assessmentId}`);
                  }} color="light" >
                    <IonIcon className="" icon={listCircleOutline}/></IonFabButton>
                </IonFab>
                <div className="score-result">
                  <div className="points">{assessmentPre.finalScore()}</div>
                  <div className="concept">{assessmentPre.finalConcept()}</div>
                </div>
                <Logo logotipo={false}/>
              </div>

            </div>
            <IonCard className="card__first card-bottom">
              <IonCardContent className="">
                <IonItem onClick={() => reportRMI()} lines="none" className="ion-no-padding">
                  <IonIcon icon={downloadOutline} slot="start"/>
                  <IonLabel>Gerar extrato bruto de exame</IonLabel>
                </IonItem>
                {/* <IonItem onClick={() => shareScore()} lines="none" className="ion-no-padding">
                  {isPlatform('desktop')
                    ? (<>
                      <IonIcon icon={mailOutline} slot="start"/>
                      <IonLabel>Compartilhar por e-mail</IonLabel>
                    </>)
                    : (<>
                      <IonIcon icon={shareOutline} slot="start"/>
                      <IonLabel>Compartilhar avaliação</IonLabel>
                    </>)}
                </IonItem> */}
              </IonCardContent>
            </IonCard>
          </div>
        )}
      </IonContent>
      <IonFooter>
        <NavBar/>
      </IonFooter>
    </IonPage>
  );
};

export default Score;
