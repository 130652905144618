import {
  IonContent, IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from '@ionic/react';
import React from 'react';
import {RouteComponentProps, useHistory, withRouter} from 'react-router-dom';
import {
  bookOutline,
  businessOutline,
  exitOutline,
  helpCircleOutline,
  peopleOutline,
  personOutline,
  statsChartOutline,
  helpBuoyOutline
} from 'ionicons/icons';
import './Menu.scss';
import {useMenuRestrictions} from './hooks/useMenuRestrictions';
import useAuth from '../User/hooks/useAuth';
import {useFirebase} from 'react-redux-firebase';
import {UserPresenter} from '../User/UserPresenter';
import { useSelector } from 'react-redux';
import { RootReducerType } from '../../types/Reducers.d';
import rootReducers from '../../store/reducers/rootReducers';


interface MenuProps extends RouteComponentProps, RouteComponentProps {

}

interface AppPage {
  url?: string | undefined;
  onClick?(): void;
  icon: string;
  title: string;
  adminOnly: boolean;
  showFor: 'loggedIn' | 'loggedOut' | 'both';
}


const Menu: React.FunctionComponent<MenuProps> = ({location}) => {

  const firebase = useFirebase();
  const history = useHistory();
  const [isLogged, profile, auth] = useAuth();
  const disabled = useMenuRestrictions([
    '/login', '/about', '/help'
  ]);

  const user = new UserPresenter(profile);
  const version = useSelector((s:RootReducerType) => s.config.app.version)

  const appPages: AppPage[] = [
    {
      title: 'Meu perfil',
      url: '/profile',
      icon: personOutline,
      adminOnly: false,
      showFor: 'loggedIn'
    },
    {
      title: 'Tutorial',
      url: '/tutorial',
      icon: helpBuoyOutline,
      adminOnly: false,
      showFor: 'loggedIn'
    },
    {
      title: 'Avaliações',
      url: '/assessments',
      icon: statsChartOutline,
      adminOnly: false,
      showFor: 'loggedIn'
    },
    {
      title: 'Entidades',
      url: '/entities',
      icon: businessOutline,
      adminOnly: false,
      showFor: 'loggedIn'
    },
    // {
    //   title: 'Manual',
    //   url: '/manual',
    //   icon: bookOutline,
    //   adminOnly: false,
    //   showFor: 'loggedIn'
    // },
    {
      title: 'Auditores',
      url: '/auditors',
      icon: peopleOutline,
      adminOnly: true,
      showFor: 'loggedIn'
    },
    {
      title: 'Ajuda',
      url: '/help',
      icon: helpCircleOutline,
      adminOnly: false,
      showFor: 'both'
    },
    {
      title: 'Sair',
      onClick: () => {
        firebase.logout();
        history.push('/login')
      },
      icon: exitOutline,
      adminOnly: false,
      showFor: 'loggedIn'
    },
    {
      title: 'Login',
      url: '/login',
      icon: exitOutline,
      adminOnly: false,
      showFor: 'loggedOut'
    },

  ];

  return (
    <IonMenu contentId="main" menuId="mainMenu" type="overlay" disabled={disabled}>
      <IonContent>
        <IonList id="inbox-list">
          <div className="menu-profile">
            <IonListHeader>{isLogged && profile.name}</IonListHeader>
            <IonNote>{isLogged && profile.email}</IonNote>
            <IonNote>{user.typeString().toUpperCase()}</IonNote>

          </div>

          {appPages.map((appPage, index) => {
            let show: boolean = appPage.showFor === 'both';
            if (appPage.showFor === 'loggedIn' && isLogged) {
              show = true;
            }
            if(!user.isAdmin() && appPage.adminOnly){
              show = false;
            }
            return (
              show && (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    className={location.pathname === appPage.url ? 'selected' : ''}
                    routerLink={appPage.url}
                    onClick={appPage.onClick}
                    routerDirection="none"
                    lines="none" detail={false}>
                    <IonIcon slot="start" icon={appPage.icon}/>
                    <IonLabel>{appPage.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              )
            );
          })}
        </IonList>

        {/*<IonList id="labels-list">*/}
        {/*  <IonListHeader>Labels</IonListHeader>*/}
        {/*  {labels.map((label, index) => (*/}
        {/*    <IonItem lines="none" key={index}>*/}
        {/*      <IonIcon slot="start" icon={bookmarkOutline} />*/}
        {/*      <IonLabel>{label}</IonLabel>*/}
        {/*    </IonItem>*/}
        {/*  ))}*/}
        {/*</IonList>*/}
      </IonContent>
      <IonFooter>
        <IonItem>{version}</IonItem>
      </IonFooter>
    </IonMenu>
  );
};

export default withRouter(Menu);
