import React from 'react';
import Logo from '../Logo';

import './SplashScreen.scss';

function SplashScreen() {
  return (
    <div data-comp-splashscreen={true} className="">
      <div className="wrapper">
        <Logo className="rotating-xslow" logotipo={false}/>
        <div className="msg">Aguarde...</div>
      </div>
    </div>
  );
}

export default SplashScreen;
