import {
  IonButton,
  IonCard,
  IonCardContent,
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonPage, IonText,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import React, {useMemo, useState} from 'react';
import {RouteComponentProps} from 'react-router';
import NavBar from '../../components/NavBar';
import {AssessmentPresenter} from '../../components/Assessment/AssessmentPresenter';
import PageHeader from '../../components/Assessment/PageHeader';
import AxisBar from '../../components/Assessment/AxisBar';
import {Assessment, AXIS} from '../../types/Assessments.d';
import useAssessment from '../../components/Assessment/hooks/useAssesment';
import {useDispatch, useSelector} from 'react-redux';
import {updateScore} from '../../store/actions/progressActions';
import {AlertRedo} from '../../components/UI/Alert/AlertRedo';
import './AssessmentsAxes.scss';
import { RootReducerType } from '../../types/Reducers.d';
import { progressFinished } from '../../support/helpers';
import { IonLabel } from '@ionic/react';
import StatusRing from '../../components/Assessment/StatusRing';


interface AssessmentsAxesProps {
}

type MyProps = AssessmentsAxesProps & RouteComponentProps<{ assessmentId: string }>;

const AssessmentsAxes = ({match}: MyProps) => {

  const disp = useDispatch();
  const assessment: Assessment = useAssessment(match.params.assessmentId, false);

  const memoPageContent = useMemo(() => {
    if (assessment) {
      disp(updateScore(assessment));
      return <RenderPageContent assessment={assessment}/>;
    }
  }, [assessment]);


  function RenderPageContent(props: any) {
    let assessmentPres: AssessmentPresenter;
    assessmentPres = new AssessmentPresenter(props.assessment);
    let percents: any = assessmentPres.getAxesPercent();
    const [redo, setRedo] = useState(false);


    return (
      <>
        <div className="ion-padding-horizontal flex">
          <PageHeader assessment={assessmentPres}/>


        {/* <IonItem color="medium" button lines="none" style={{borderRadius: 10}}>
          <IonLabel>Pronto para consolidar</IonLabel>
        </IonItem> */}
        </div>
        <IonCard>
          <IonCardContent>
            {
              Object.keys(assessmentPres.responses).sort().map((axisKey) => {
                let axis = AXIS[axisKey];

                return <AxisBar
                  key={axisKey}
                  name={axis.name.pt}
                  axis={axisKey}
                  percents={percents}
                  assessmentId={assessmentPres.id}
                />;
              })
            }
          </IonCardContent>
        </IonCard>
        <AlertRedo isOpen={redo} onDismiss={() => {
          setRedo(false);
        }}/>
        <IonText
          className="ion-padding-horizontal "
          onClick={() => {
            setRedo(true);
          }} color="secondary">Reiniciar avaliação</IonText>

      </>
    );
  }

  return (
    <IonPage>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle>Instâncias/demandas</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div data-comp-axes={true} className="content">
          {memoPageContent}
        </div>
      </IonContent>
      <IonFooter>
        <NavBar/>
      </IonFooter>
    </IonPage>
  );
};

export default AssessmentsAxes;
