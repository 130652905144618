import React from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonLabel,
  IonItemGroup, IonItemDivider, IonButtons, IonButton, IonFooter
} from '@ionic/react';
import {useHistory} from 'react-router';
import NavBar from '../../components/NavBar';
import useAuth from '../../components/User/hooks/useAuth';
import './Help.scss'

const Help = () => {

  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLogged, profile, auth] = useAuth();

  return (
    <IonPage>
      <IonContent>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Ajuda</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={()=>{
                history.goBack();
              }} color="secondary">voltar</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div data-comp-help={true} className="content ion-padding">

          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>O que é Código do Credenciado?</IonLabel>
            </IonItemDivider>
            <IonItem>
              <p>É a senha para entrada no Sistema 5R-INDEX, hospedado em nuvem, com a finalidade de acessar o Manual de Aplicação e o aplicativo (em "smartphones") para o lançamento dos “scores” de 200 quesitos do exame. Com este Código você pode atuar em quantos “jobs” quiser, simultaneamente.</p>
            </IonItem>
          </IonItemGroup>

          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>Como validar meu Código?</IonLabel>
            </IonItemDivider>
            <IonItem>
              <div>
              <p>No app, entre com o códido de compra na Amazon. Aguarde a validação.</p>
              <p>Envie o comprovante para o email <strong>suporte@5r-index.com.br</strong></p>
              <p>Após a validação, você poderá adicionar entidades e iniciar o processo de avaliação.</p>
              </div>
            </IonItem>
          </IonItemGroup>

          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>Dicas de navegação</IonLabel>
            </IonItemDivider>
            <IonItem>
              <p><img src="./assets/help-01.png" className={``} alt=" "/></p>
            </IonItem>
            <IonItem><p><img src="./assets/help-02.png" className={``} alt=" "/></p></IonItem>
            <IonItem><p><img src="./assets/help-03.png" className={``} alt=" "/></p></IonItem>
          </IonItemGroup>

        </div>
      </IonContent>
      {isLogged && (
        <IonFooter>
          <NavBar/>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default Help;
