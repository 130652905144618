import React from 'react';
import {IonButton, IonSlide, IonSlides} from '@ionic/react';
import './FirstSteps.scss';
import {useHistory} from 'react-router';

const FirstSteps = () => {
  const history = useHistory();
  const slideOpts = {
    initialSlide: 0,
    speed: 400
  };
  return (
    <div data-comp-tutorial={true}>
      <IonSlides scrollbar={false} pager={true} options={slideOpts}>
        <IonSlide>
          <small className="pre-title">passo 1</small>
          <div className="img">
            <img src="/assets/passo1.svg" alt="passo 1"/>
          </div>
          <h1>Criar entidade</h1>
          <p>Vá em "Entidades" e adicione as entidades que pretende avaliar.</p>
        </IonSlide>
        <IonSlide>
          <small className="pre-title">passo 2</small>
          <div className="img">
            <img src="/assets/passo2.svg" alt="passo 2"/>
          </div>
          <h1>Iniciar avaliação</h1>
          <p>Vá em "Avaliações" e selecione a entidade que quer avaliar. Clique em INICIAR para começar a avaliação.</p><p><small>Se surgir alguma dúvida, consulte o manual.</small></p>
        </IonSlide>
        <IonSlide>
          <small className="pre-title">passo 3</small>
          <div className="img">
            <img src="/assets/passo3.svg" alt="passo 3"/>
          </div>
          <h1>Finalizar</h1>
          <p>Após responder todas as questões, você poderá consolidar a avaliação e gerar o score final.</p>

        </IonSlide>
        <IonSlide>
          <small className="pre-title">passo 4</small>
          <div className="img">
            <img src="/assets/passo4.svg" alt="passo 4"/>
          </div>
          <h1>Compartilhar</h1>
          <p>Ao consolidar uma avaliação, você pode compartilhar o resultado.</p>
          <p><IonButton onClick={()=>{
            history.push(`/entities`)
          }} expand="block" fill="solid" shape="round" color="medium">Começar agora</IonButton></p>
          <p>
            <IonButton expand="block" fill="outline" size="small" shape="round" color="medium" onClick={()=>{
              history.push(`/tutorial`)
            }}>Passo a passo da avaliação</IonButton>
          </p>
        </IonSlide>
      </IonSlides>
    </div>
  );
};

export default FirstSteps;
