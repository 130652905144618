import {useFirestoreConnect} from 'react-redux-firebase';
import {useSelector} from 'react-redux';
import {RootReducerType} from '../../../types/Reducers.d';

function useAssessment(assessmentId: string, useCurrent: boolean = true) {

  let storeAs = useCurrent ? 'current_assessment' : assessmentId;

  useFirestoreConnect([{
    collection: 'assessments',
    storeAs: storeAs,
    doc: assessmentId
  }]);

  return useSelector((state: RootReducerType) => state.firestore.data[storeAs]);
}

export default useAssessment;
