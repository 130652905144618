import React from "react";
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonSlides,
  IonSlide,
  IonButton,
  IonCard,
} from "@ionic/react";
import { useHistory } from "react-router";
import './Tutorial.scss';
import { IonCardContent } from '@ionic/react';

export default function Tutorial() {
  const history = useHistory();
  const slideOpts = {
    initialSlide: 0,
    speed: 400,
  };
  const steps = Array(12).fill(0);
  return (
    <IonPage>
      <IonContent>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Tutorial</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div data-page-tutorial={true}>
          <IonSlides scrollbar={false} pager={true} options={slideOpts}>
          {steps.map((step, i) => {
            let n: string | number = i + 1;
            n = n < 10 ? `0${n}` : n;
            return <IonSlide>
              <IonCard>
                <img src={`assets/tuto/tuto-${n}.png`} alt={`Passo ${n}`} />
              </IonCard>
            </IonSlide>
          })}
            <IonSlide>
              <IonCard style={{width: '100%', maxWidth: '360px', height: '70%'}}>
                <IonCardContent className="final-content" style={{height: '100%'}}>
                  <p><IonButton expand="block" shape="round" onClick={()=>{
                    history.push(`/assessments`)
                  }}>Iniciar avaliação</IonButton></p>
                  <p>ou</p>
                  <p><IonButton expand="block" shape="round" fill="outline" onClick={()=>{
                    history.push(`/entities`)
                  }}>Criar entidade</IonButton></p>

                </IonCardContent>
              </IonCard>
            </IonSlide>
          </IonSlides>
        </div>
      </IonContent>
    </IonPage>
  );
}
