import React, {useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router';
import {IonAlert} from '@ionic/react';
import {useDispatch} from 'react-redux';
import {Assessment, AssessmentScore} from '../../../types/Assessments.d';
import {consolidateAssessment, rebuildResponses} from '../../../store/actions/assessmentActions';
import Loading from '../Loading';
import useAssessment from '../../Assessment/hooks/useAssesment';

export function AlertRedo(props: { isOpen: boolean, onDismiss: () => void }) {

  const disp = useDispatch();
  const history = useHistory();
  const {params: {assessmentId}} = useRouteMatch<{ assessmentId: string }>();
  const [loading, setLoading] = useState(false);
  const assessment: Assessment = useAssessment(assessmentId);

  function didPresent() {

  }

  function doRedo() {

    // set loading
    setLoading(true);

    disp(rebuildResponses(assessmentId, assessment.version));

    // reload page
    setTimeout(() => {
      setLoading(false);
    }, 1000)

  }

  return (
    <>
      <Loading isOpen={loading} />
      <IonAlert
        cssClass="rindex-consolidate-alert"
        backdropDismiss={true}
        isOpen={props.isOpen}
        onDidPresent={didPresent}
        onDidDismiss={() => props.onDismiss()}
        header="Reiniciar"
        subHeader="Ao reiniciar todas as respostas serão perdidas. Esta operação não pode ser desfeita."
        message={`<p>Deseja continuar?</p>`}
        buttons={[
          {
            text: 'CANCELAR',
            cssClass: 'secondary',
            role: 'cancel'
          }, {
            text: 'REINICIAR',
            role: 'OK',
            cssClass: 'primary',
            handler: doRedo
          },
        ]}
      />
    </>
  );
}
