import {Profile, UserType} from '../../types/User.d';

export class UserPresenter implements Profile {
  avatar: string = '';
  createdAt: string = '';
  email: string = '';
  id: string = '';
  isEmpty: boolean = true;
  isLoaded: boolean = false;
  name: string = '';
  phone: string = '';
  provider: string = '';
  occupation: number = 0;
  type: UserType = 2;
  code:{code: null|string; valid: boolean; rated_at: string} = {code: null, valid: false, rated_at: ''};


  constructor(user: Profile) {
    Object.assign(this, user);
  }

  isAuditor(): boolean{
    return this.type === 2;
  }
  isManager(): boolean{
    return this.type === 5;
  }
  isAdmin(): boolean{
    return this.type === 8;
  }
  isNotAdmin(): boolean{
    return this.type < 8;
  }
  typeString(): string {
    let type = this.isAuditor() ? 'Auditor' : 'Gestor';
    return this.isAdmin() ? 'Admin' : type;
  }

  hasValidCode(): boolean{
    return this.code.valid && this.getCode().length > 0;
  }
  getCode(): string{
    return this.code.code || '';
  }
}
