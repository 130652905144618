import React from 'react';
import { IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel} from '@ionic/react';

import { EntityItemProps, Entity } from '../../types/Entities.d';
import { useDispatch } from 'react-redux';
import { createAssessment } from '../../store/actions/assessmentActions';
import { useHistory } from 'react-router';

const EntityItem = ({onClick, entity}: EntityItemProps) => {

  const disp = useDispatch();
  const history = useHistory();

  function onClickHandler() {
    onClick(entity)
  }

  function handleNewAssessment(entity: Entity){
    disp(createAssessment(entity));
    history.push(`/assessments`)
  }

  function handleDelete(){
    console.log(entity);

  }

  return (
    <div className="ion-padding-bottom">
      <IonItemSliding>
        <IonItem className="rindex-item ion-no-padding" lines="none" detail={false} onClick={onClickHandler}>
          <IonLabel>
            <h2 className="label">{entity.name}</h2>
            {/* TODO Autor só é exibido para Admins */}
            {/* <div className="note">Autor: Fulano da Silva</div> */}
          </IonLabel>
        </IonItem>
        {/* TODO Este recurso ainda está em avaliação */}
        <IonItemOptions side="end">
          <IonItemOption color="success" onClick={()=>handleNewAssessment(entity)}>NOVA AVALIAÇÃO</IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    </div>
  );
};
export default EntityItem;
