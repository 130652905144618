import React from 'react';
import {IonTabBar, IonTabButton, IonIcon, IonLabel} from '@ionic/react';
import {businessOutline, bookOutline, ellipsisHorizontalOutline, statsChartOutline, helpCircleOutline} from 'ionicons/icons';
import {menuController} from '@ionic/core';
import './NavBar.scss';

const NavBar = () => {


  return (
    <IonTabBar mode="md" data-comp-navbar>
      <IonTabButton tab="assessments" href="/assessments">
        <IonIcon icon={statsChartOutline}/>
        <IonLabel>Avaliações</IonLabel>
      </IonTabButton>

      <IonTabButton tab="entities" href="/entities">
        <IonIcon icon={businessOutline}/>
        <IonLabel>Entidades</IonLabel>
      </IonTabButton>

      <IonTabButton tab="help" href="/help">
        <IonIcon icon={helpCircleOutline}/>
        <IonLabel>Ajuda</IonLabel>
      </IonTabButton>


      <IonTabButton onClick={(e) => {
        e.preventDefault();
        menuController.open('mainMenu');
      }}>
        <IonIcon icon={ellipsisHorizontalOutline}/>
        <IonLabel>Mais</IonLabel>
      </IonTabButton>


    </IonTabBar>
  );
};

export default NavBar;
