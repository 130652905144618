import {Dispatch} from 'redux';
import {Entity} from '../../types/Entities.d';
import {Error} from '../../types/Reducers.d';
import Api from '../../support/api';

export const ENTITY_ACTION_TYPE = {
  LOAD: 'LOAD_ENTITY',
  CREATED: 'ENTITY_CREATED',
  CREATED_ERR: 'ENTITY_CREATED_ERROR',
  UPDATED: 'ENTITY_UPDATED',
  UPDATED_ERR: 'ENTITY_UPDATED_ERROR',
};


export const loadEntity = (entity: Entity | null | undefined, callback?: Function) => {
  return (dispatch: Dispatch, getState: any, getFirebase: any) => {
    dispatch({type: ENTITY_ACTION_TYPE.LOAD, payload: entity});
    if (typeof callback !== 'undefined') callback();
  };
};

export const addUpdateEntity = (
  entity: Entity,
  callback?: (entityId: boolean | string, err?: Error) => void
) => {
  return async (dispatch: Dispatch, getState: any, getFirebase: any) => {
    let action: string = entity.id.length ? 'update' : 'create';
    // ID is CNPJ numbers
    let id: string = (action === 'create')
      ? entity.cnpj.replace(/\D/g, '')
      : entity.id;

    // Validate is ID exists
    if (id.length === 0) {
      let err = {code: 'badfield/cnpj', message: 'CNPJ vazio ou inválido'};
      dispatch({type: ENTITY_ACTION_TYPE.CREATED_ERR, payload: err});
      if (typeof callback !== 'undefined') callback(false, err);
      return;
    }

    // check if entity exists
    const exists: boolean = await checkEntityExists(getFirebase(), id);

    if (exists) {

      try {
        await updateFullEntity(getFirebase(), entity);
        dispatch({type: ENTITY_ACTION_TYPE.UPDATED, payload: entity});
        Api.syncEntity({
          id: entity.id,
          cnpj: entity.cnpj,
          name: entity.name,
          ownerName: entity.ownerName,
          ownerEmail: entity.ownerEmail,
          city: entity.city,
          state: entity.state,
          size: entity.size,
          type: entity.type,
          segment: entity.segment,
        })
        if (typeof callback !== 'undefined') callback(entity.id);
      } catch (e) {
      }

    } else {
      // save entity
      try {
        entity.id = id;
        await createEntity(getFirebase(), entity);
        Api.syncEntity({
          id: entity.id,
          cnpj: entity.cnpj,
          name: entity?.name || '',
          ownerName: entity?.ownerName || '',
          ownerEmail: entity?.ownerEmail || '',
          city: entity?.city || '',
          state: entity?.state || '',
          size: entity?.size || '',
          type: entity?.type || '',
          segment: entity?.segment || '',
        })
        dispatch({type: ENTITY_ACTION_TYPE.CREATED, payload: entity});
        if (typeof callback !== 'undefined') callback(entity.id);
      } catch (e) {
      }

    }


  };
};

/**
 * Check if entity exists on Firebase
 * @param firebase
 * @param id
 */
function checkEntityExists(firebase: any, id: string): Promise<any> {
  return firebase.firestore().collection('entities').doc(id).get()
    .then((doc: firebase.firestore.DocumentSnapshot) => doc.exists);
}

/**
 * create a new entity
 * @param firebase
 * @param entity
 */
function createEntity(firebase: any, entity: Entity): Promise<any> {
  return firebase.firestore().collection('entities')
    .doc(entity.id)
    .set(entity);
}

/**
 * Update entity on entity edition
 * @param firebase
 * @param entity
 */
function updateFullEntity(firebase: any, entity: Entity): Promise<any> {
  let data = entity;
  // @ts-ignore
  delete data['auditor'];
  // @ts-ignore
  delete data['assessmentId'];

  return firebase.firestore().collection('entities')
    .doc(entity.id)
    .update(data);
}

/**
 * Update entity
 * @param firebase
 * @param entityId
 * @param data
 */
export function updateEntity(firebase: any, entityId: string, data: any): Promise<any> {

  return firebase.firestore().collection('entities')
    .doc(entityId)
    .update(data);
}
