import React from 'react';

interface ColorBarProps {
  score: Object;
}

const ColorBar: React.FC<ColorBarProps> = (props: ColorBarProps) => {
  const score: any = props.score;
  return (
    <div className="rindex-color-bar">
      {Object.keys(score).map((axis: string) => {
        let width = score[axis].percent;
        return (<div key={axis} className={`axis ${axis}`}>
          <div style={{width: width+'%'}}/>
        </div>)
      })}
    </div>
  );
};

export default ColorBar;
