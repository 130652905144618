// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios, {AxiosInstance, AxiosResponse} from 'axios';
import { Assessment } from '../types/Assessments.d';

type LogType = 'user/created'
  | 'user/updated'
  | 'entity/created'
  | 'assessment/init'
  | 'assessment/end';

export default class Api {

  static WEB_API = 'https://5r-index.com.br/api/public';
  static APP_API = 'https://5r-index.com.br/api/public/api';

  static getWebUrl() {
    return process.env.REACT_APP_WEB_BASEURL || Api.WEB_API;
  }

  static getApiUrl() {
    return process.env.REACT_APP_API_BASEURL || Api.APP_API;
  }

  /**
   * Retrieve all configs
   */
  static configs(): Promise<AxiosResponse<any>> {
    return http.get(`/configs`);
  }

  static syncUser(profile: {
    uid: string; name: string; avatar?: string; email: string; phone: string; occupation?: number; type?: number
  }): Promise<AxiosResponse<any>> {
    return http.post(`/user/sync`, {...profile});
  }

  static syncEntity(entity: {
    id: number|string; name: string; ownerName: string; ownerEmail: string; cnpj: string; city:string,
    state:string,
    size:string,
    type:string,
    segment:string,
  }): Promise<AxiosResponse<any>> {
    return http.post(`/entity/sync`, {...entity});
  }

  /**
   * Check if the code is valid for
   * @param uid
   */
  static isCodeValid(uid?: string | undefined): Promise<AxiosResponse<any>> {
    return http.post(`/code/check`, {uid});
  }

  static validateCode(code: string): Promise<AxiosResponse<any>> {
    return http.post(`/code/validate`, {code});
  }

  static log(data: {
    type: LogType;
    entity_id?: string;
    entity_name?: string;
    assessment_id?: string;
    score_points?: number|string;
    score_concept?: string;
    data?: any
  }): Promise<AxiosResponse<any>> {
    return http.post(`/log`, {...data});
  }

  static registerAssessment(assessment: Assessment): Promise<AxiosResponse<any>> {
    return http.post(`/assessment`, {...assessment});
  }
}

/**
 * @link https://github.com/axios/axios
 * @type {AxiosInstance}
 */
export const http = axios.create({
  baseURL: Api.getApiUrl(),
  timeout: 0,
  headers: {
    // 'X-Custom-Header': 'foobar'
  }
});
