import React from 'react';
import {IonLoading} from '@ionic/react';

interface LoadingProps {
  isOpen: boolean;
  message?: string;
  onDismiss?: Function;
}

const Loading: React.FC<LoadingProps> = (props: LoadingProps) => {
  function noop() {
  }

  const msg: string = typeof props.message === 'string' ? props.message : '';
  const cb = typeof props.onDismiss === 'function' ? props.onDismiss : noop;

  return (
    <IonLoading
      isOpen={props.isOpen}
      onDidDismiss={()=> cb()}
      message={msg}
    />
  );
};

export default Loading;
