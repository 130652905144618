import {ASSESSMENT_ACTION_TYPE} from '../actions/assessmentActions';
import {Assessment} from '../../types/Assessments.d';
import {AssessmentPresenter} from '../../components/Assessment/AssessmentPresenter';

const initState = {};

const ProgressReducer = (state: {} = initState, action: { type: string; payload: Assessment }) => {

  if (action.type === ASSESSMENT_ACTION_TYPE.ANSWER_UPDATED
    || action.type === ASSESSMENT_ACTION_TYPE.UPDATE_SCORE) {
    // console.log('ProgressReducer', action.payload);
    const assessment = new AssessmentPresenter(action.payload);
    // const progress =
    return {
      ...state,
      [assessment.id]: {score: assessment.getAxesScore()}
    };
  }

  return state;
};

export default ProgressReducer;
