import React, {useEffect, useState} from 'react';
import {IonCard, IonCardContent, IonCol, IonGrid, IonRow} from '@ionic/react';

import './StatusHeader.scss';
import StatusRing from './StatusRing';
import EntityInfo from './EntityInfo';
import {AssessmentPresenter} from './AssessmentPresenter';
import {useSelector} from 'react-redux';
import {RootReducerType} from '../../types/Reducers.d';
import {progressFinished} from '../../support/helpers';

interface StatusHeaderProps {
  entity: { id: string; name: string };
  assessment: AssessmentPresenter;
}

const StatusHeader = (props: StatusHeaderProps) => {

  const progress = useSelector((status: RootReducerType) => status.progress[props.assessment.id]);
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    if(progress?.score){
      setFinished(progressFinished(progress.score))
    }
  }, [progress]);

  return (
    <div data-comp-statusheader={true}>
      <IonCard className="card__first">
        <IonCardContent>
          <IonGrid>
            <IonRow className="ion-align-items-center">
              <IonCol style={{flexGrow: 5}}>
                <strong className="entity">{props.entity.name}</strong>
              </IonCol>
              <IonCol>
                <EntityInfo assessment={props.assessment} entityId={props.entity.id}/>
              </IonCol>
              <IonCol>
                {progress && progress?.score &&
                <StatusRing
                  finished={finished}
                  score={progress.score}/>}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>
    </div>
  );
};

export default StatusHeader;
