import React, {useState} from 'react';
import {IonButton, IonIcon, IonPopover} from '@ionic/react';
import {AXIS} from '../../types/Assessments.d';
import {useHistory, useRouteMatch} from 'react-router';
import {checkmarkCircleOutline} from 'ionicons/icons';
import {AlertConsolidate} from '../UI/Alert/AlertConsolidate';

interface StatusRingProps {
  score: any;
  finished: boolean;
}

const StatusRing = ({score, finished}: StatusRingProps) => {

  const [showPopover, setShowPopover] = useState(false);
  const [popEvent, setPopEvent] = useState<React.MouseEvent<HTMLDivElement>>();
  const [consolidate, setConsolidate] = useState(false);
  const match = useRouteMatch<{assessmentId: string}>()
  const history = useHistory()

  function PopOver({isOpen, event, score}: any) {

    function onClick(axes: string) {
      setShowPopover(false)
      history.push(`/status/${match.params.assessmentId}/${axes}`);
    }

    return (
      <IonPopover
        cssClass="rindex-status-popopver"
        event={event}
        isOpen={isOpen}
        onDidDismiss={e => setShowPopover(false)}
      >
        {Object.keys(score).sort().map((axisKey: string) => {
          return (<div onClick={() => onClick(axisKey)} key={axisKey} className={`axis ${axisKey}`}>
            <div>{AXIS[axisKey].name.pt}</div>
            <div>
              {score[axisKey].answered}<span className="trans">/{score[axisKey].total}</span>
            </div>
          </div>);
        })}
      </IonPopover>
    );
  }

  function onDismissAlert(){
    setConsolidate(false);
  }

  return (<>
      <div onClick={(e) => {
        e.persist();
        setPopEvent(e);
        if(finished){
          setConsolidate(true);
        } else {
          setShowPopover(true);
        }
      }} className="ring">
        {finished && <IonIcon className="finished" size="large" icon={checkmarkCircleOutline}/>}
        <img src="/assets/status-ring.svg" alt="status"/>
      </div>
      <PopOver event={popEvent} isOpen={showPopover} score={score}/>
      <AlertConsolidate isOpen={consolidate} onDismiss={onDismissAlert}/>
    </>
  );
};

export default StatusRing;
