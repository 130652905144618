import React from 'react';
import {IonAlert} from '@ionic/react';

interface AlertStartEvaluationProps {
  onStart(): void;
  isOpen: boolean;
  onDismiss(): void;
}

const AlertStartEvaluation = (props: AlertStartEvaluationProps) => {

  return (
    <IonAlert
      cssClass="rindex-start-evaluation"
      backdropDismiss={true}
      isOpen={props.isOpen}
      // onDidPresent={didPresent}
      onDidDismiss={() => props?.onDismiss()}
      header={'Avaliação'}
      subHeader={`Iniciar avaliação da entidade?`}
      // message={``}

      buttons={[
        {
          text: 'CANCELAR',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'INICIAR',
          role: 'OK',
          cssClass: 'primary',
          handler: props.onStart
        },
      ]}
    />
  );
};

export default AlertStartEvaluation;
