export default [
  {
    id: '1',
    name: 'Uma empresa ZYX',
    cnpj: '0123',
    ownerName: 'João',
    ownerEmail: 'zyx@mail.com',
    assessmentId: [],
    auditor: '',
    city:"",
    state:"",
    size:"",
    type:"",
    segment:"",
  },
  {
    id: '2',
    name: 'Entidade ABC',
    cnpj: '',
    ownerName: '',
    ownerEmail: '',
    assessmentId: [],
    auditor: '',
    city:"",
    state:"",
    size:"",
    type:"",
    segment:"",
  },
  {
    id: '3',
    name: 'Company Bla',
    cnpj: '',
    ownerName: '',
    ownerEmail: '',
    assessmentId: [],
    auditor: '',
    city:"",
    state:"",
    size:"",
    type:"",
    segment:"",
  },
  {
    id: '4',
    name: 'Entidade ABC',
    cnpj: '',
    ownerName: '',
    ownerEmail: '',
    assessmentId: [],
    auditor: '',
    city:"",
    state:"",
    size:"",
    type:"",
    segment:"",
  },
  {
    id: '5',
    name: 'Uma empresa ZYX',
    cnpj: '',
    ownerName: '',
    ownerEmail: '',
    assessmentId: [],
    auditor: '',
    city:"",
    state:"",
    size:"",
    type:"",
    segment:"",
  },
  {
    id: '6',
    name: 'Entidade ABC',
    cnpj: '',
    ownerName: '',
    ownerEmail: '',
    assessmentId: [],
    auditor: '',
    city:"",
    state:"",
    size:"",
    type:"",
    segment:"",
  },
  {
    id: '7',
    name: 'Uma empresa ZYX',
    cnpj: '',
    ownerName: '',
    ownerEmail: '',
    assessmentId: [],
    auditor: '',
    city:"",
    state:"",
    size:"",
    type:"",
    segment:"",
  },
  {
    id: '8',
    name: 'Entidade ABC',
    cnpj: '',
    ownerName: '',
    ownerEmail: '',
    assessmentId: [],
    auditor: '',
    city:"",
    state:"",
    size:"",
    type:"",
    segment:"",
  },
];
