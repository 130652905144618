import React, {Suspense, useState} from 'react';
import {IonButton, IonCard, IonCardContent, IonCardTitle, IonIcon} from '@ionic/react';
import {logoFacebook, logoGoogle, logoTwitter} from 'ionicons/icons';
import {AxiosResponse} from 'axios';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import firebase from 'firebase/app';

import {AlertCode} from '../UI/Alert/AlertCode';
import {AlertWelcome} from '../UI/Alert/AlertWelcome';
import {hasValidCode, signInWithSocial} from '../../store/actions/authActions';
import {Error} from '../../types/Reducers.d';
import useAuth from '../User/hooks/useAuth';
import './LoginButtons.scss';


const LoginButtons: React.FC = () => {

  const disp = useDispatch();
  const [showCode, setShowCode] = useState(false);
  const [showWelcome, setShowWelcome] = useState(false);
  const [isLogged, profile] = useAuth();
  const history = useHistory();

  function doSocialLogin(brand: string) {

    disp(signInWithSocial({provider: brand},
      (success: boolean, userError: firebase.auth.UserCredential | Error) => {

        if (success) {

          disp(hasValidCode((resp: any) => {

            if (resp.valid) {
              goToHome();
            } else if(resp.code){
              history.push('/profile');
            } else {
              setShowCode(true);
            }
          }));

        } else {
          alert('Erro ao fazer login');
        }

      }));

  }


  function goToHome() {
    history.push('/');
  }

  return (
    <>
      <AlertCode isOpen={showCode} onDismiss={() => setShowCode(false)}
                 showWelcome={() => {
                   setShowCode(false);
                   setShowWelcome(true);
                 }}/>
      <AlertWelcome isOpen={showWelcome} onDismiss={() => setShowWelcome(false)}/>
      <IonCard className="login--card">
        <IonCardTitle className="login--title">Entrar</IonCardTitle>
        <IonCardContent>
          <Suspense fallback={`Aguarde...`}>
            <div className="social--links">
              {(isLogged && profile.name) ? (
                <IonButton onClick={goToHome} fill="outline" color="primary" expand="block">
                  Olá, {profile.name.split(' ')[0]}. Seja bem-vindo.
                </IonButton>
              ) : (
                <IonButton onClick={() => doSocialLogin('google')} fill="outline" color="secondary" expand="block">
                  <IonIcon className="social--icon" icon={logoGoogle}/>
                  <span style={{margin: '0 0 0 10px'}}>Entrar com Google</span>
                </IonButton>
              )}


              {/*<IonIcon onClick={() => doSocialLogin('facebook')}*/}
              {/*         className="social--icon" icon={logoFacebook}/>*/}
              {/*<IonIcon onClick={() => doSocialLogin('twitter')}*/}
              {/*         className="social--icon" icon={logoTwitter}/>*/}
            </div>
          </Suspense>
        </IonCardContent>
      </IonCard>
    </>
  );
};

export default LoginButtons;
