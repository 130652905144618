import {
  IonCard,
  IonCardContent,
  IonContent,
  IonFooter,
  IonHeader,
  IonList,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import React, {useEffect, useState} from 'react';
import NavBar from '../../components/NavBar';
import FirstSteps from '../../components/FirstSteps';
import AssessmentsList from '../../components/Assessment/AssessmentsList';
import './Assessments.scss';
// import mockdata from '../../components/Assessment/assessment-mock-data';
import useMyAssessments from '../../components/Assessment/hooks/useMyAssessments';
import Spinner from '../../components/UI/Spinner/Spinner';
import useAuth from '../../components/User/hooks/useAuth';
import {UserPresenter} from '../../components/User/UserPresenter';
import AlertCodeStatus from '../../components/UI/Alert/AlertCodeStatus';


const Assessments = () => {
  const assessments = useMyAssessments();
  const [searchText, setSearchText] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLogged, profile, auth] = useAuth();
  const [user, setUser] = useState<UserPresenter>();

  useEffect(()=>{
    setUser(new UserPresenter(profile))
  }, [profile]);

  // If show tutorial set full height of content
  const compStyles = assessments?.length ? {} : {
    height: 'calc(100% - 50px)'
  };

  function RenderAssessments() {
    return (
      <IonCard style={{marginTop: 10}}>
        <IonCardContent>
          <IonList>
            <AssessmentsList
              statusFilter={0}// TODO precisa implementar
              textFilter={searchText}
              assessments={assessments}/>
          </IonList>
        </IonCardContent>
      </IonCard>
    );
  }

  return (
    <IonPage>
      <IonHeader collapse="condense">
        <IonToolbar>
          {assessments?.length && user?.hasValidCode()
            ? (<IonSearchbar
              style={{paddingTop: 10, paddingBottom: 13}}
              value={searchText}
              debounce={600}
              onIonChange={e => setSearchText(e.detail.value!)} animated/>)
            : (<IonTitle>Avaliações</IonTitle>)}

        </IonToolbar>
        <AlertCodeStatus user={user}/>

      </IonHeader>
      <IonContent>

        <div data-comp-assessments={true} style={compStyles} className="content ">
          {!assessments && <Spinner/>}
          {assessments && assessments.length > 0 && <RenderAssessments/>}
          {assessments && assessments.length === 0 && <FirstSteps/>}
        </div>
      </IonContent>
      <IonFooter>
        <NavBar/>
      </IonFooter>
    </IonPage>
  );
};

export default Assessments;
