import {createStore, compose, applyMiddleware} from 'redux'
import thunk from 'redux-thunk';
import {getFirebase} from 'react-redux-firebase'
import reducers from './reducers/rootReducers'
import {axiosAuthMiddleware} from "../support/axios";

const initialState = {};

const middlewares = [
  thunk.withExtraArgument(getFirebase),
  axiosAuthMiddleware()
];

/**
 * On development use REDUX DEVTOOL EXTENSION
 * @link https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd?hl=pt-BR
 */
let composeEnhancers = compose;
if (process.env.NODE_ENV === 'development') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}
const store = createStore(
  reducers,
  initialState,
  composeEnhancers(
    applyMiddleware(...middlewares)
  )
)


export default store;
