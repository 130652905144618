import React from "react";
import { IonButton, IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { chevronBack, chevronForward } from "ionicons/icons";
import "./AxisNav.scss";
import { nextAxis, prevAxis, getAxis } from "../../support/helpers";
import { useHistory } from "react-router";
import QuestionsNavContext from "../../pages/AssessmentsQuestions/QuestionsNavContext";

interface AxisNavProps {
  currentAxis: string;
  assessmentId: string;
  baseRoute?: string;
}

const AxisNav = (props: AxisNavProps) => {
  const history = useHistory();
  const { currentAxis, assessmentId } = props;
  const pAxis = prevAxis(currentAxis);
  const nAxis = nextAxis(currentAxis);
  const cAxis = getAxis(currentAxis);
  const baseRoute: string = props.baseRoute || "status";

  function goToPrevAxis() {
    history.push(`/${baseRoute}/${assessmentId}/${pAxis.key}`);
  }
  function goToNextAxis() {
    history.push(`/${baseRoute}/${assessmentId}/${nAxis.key}`);
  }

  function goToPrevSlide(slidesRef: any){
    slidesRef.current?.slidePrev();
  }
  function goToNextSlide(slidesRef: any){
    slidesRef.current?.slideNext();
  }

  return (
    <QuestionsNavContext.Consumer>
      {({slidesRef}) => (
        <div data-comp-axisnav={true} className={currentAxis}>
          <IonGrid>
            <IonRow className="ion-align-items-center">
              <IonCol className="btn-col">
                <IonButton
                  onClick={()=> goToPrevAxis()}
                  fill="clear"
                  size="small"
                  strong={true}
                >
                  <IonIcon size="" icon={chevronBack} />
                </IonButton>
              </IonCol>
              {baseRoute === 'questions' &&
              <IonCol className="btn-col" style={{padding: 0}}>
                <IonButton
                  onClick={()=> goToPrevSlide(slidesRef)}
                  fill="clear"
                  size="small"
                  strong={true}
                >
                  <IonIcon size="" icon={chevronBack} />
                  <IonIcon size="" icon={chevronBack} style={{marginLeft: -17}} />
                </IonButton>
              </IonCol>}
              <IonCol style={{ flexGrow: 5 }}>
                <strong className="name">{cAxis?.name}</strong>
              </IonCol>
              {baseRoute === 'questions' &&
              <IonCol className="btn-col" style={{padding: 0}}>
                <IonButton
                  onClick={()=>goToNextSlide(slidesRef)}
                  fill="clear"
                  size="small"
                  strong={true}
                ><IonIcon size="" icon={chevronForward} style={{marginRight: -17}} />
                  <IonIcon size="" icon={chevronForward} />
                </IonButton>
              </IonCol>}

              <IonCol className="btn-col">
                <IonButton
                  onClick={()=>goToNextAxis()}
                  fill="clear"
                  size="small"
                  strong={true}
                >
                  <IonIcon size="" icon={chevronForward} />
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      )}
    </QuestionsNavContext.Consumer>
  );
};

export default AxisNav;
