import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';

import App from './App';

import * as serviceWorker from './serviceWorker';
import {createFirestoreInstance} from 'redux-firestore';
import {ReactReduxFirebaseProvider} from 'react-redux-firebase';
import store from './store/createStore';
import fbConfig, {rrfConfig, AuthIsLoaded} from './support/firebase';
import {defineCustomElements} from '@ionic/pwa-elements/loader';


const rrfProps = {
    firebase: fbConfig,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance
}


ReactDOM.render(<Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
        <AuthIsLoaded>
            <App/>
        </AuthIsLoaded>
    </ReactReduxFirebaseProvider>
</Provider>, document.getElementById('root'));

/**
 * @ionic/pwa-elements
 * https://capacitor.ionicframework.com/docs/pwa-elements/
 */
defineCustomElements(window);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
