import {Dispatch} from 'redux';
import {Assessment} from '../../types/Assessments.d';
import {ASSESSMENT_ACTION_TYPE} from './assessmentActions';


export const updateScore = (assessment: Assessment) => {
  return (dispatch: Dispatch, getState: any, getFirebase: any) => {
    dispatch({type: ASSESSMENT_ACTION_TYPE.UPDATE_SCORE, payload: assessment})
  };
};
