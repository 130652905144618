import React from "react";
import { IonIcon, IonItem, IonItemDivider, IonLabel } from "@ionic/react";
import { checkmarkSharp, chevronForwardOutline } from "ionicons/icons";
import { isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import isNull from "lodash/isNull";
import { AssessmentPresenter } from "./AssessmentPresenter";
import { RootReducerType } from "../../types/Reducers.d";
import { ResponseAxis, answerNum2Con, AXIS } from '../../types/Assessments.d';
import { useHistory } from "react-router";
import { numToQID } from "../../support/helpers";
import Spinner from "../UI/Spinner/Spinner";
import { divide } from "lodash";

interface QuestionsListProps {
  assessment: AssessmentPresenter;
  axis: string;
  mode?: "question" | "detail";
}

const QuestionsList = (props: QuestionsListProps) => {
  const { assessment, axis, mode: md } = props;
  const mode = typeof md === "undefined" ? "question" : md;

  useFirestoreConnect(`questions_${assessment.version}`);

  const history = useHistory();
  const questions = useSelector(
    (state: RootReducerType) =>
      state.firestore.data[`questions_${assessment.version}`]
  );

  if (!isLoaded(questions) || !assessment.responses) {
    return <Spinner />;
  }

  function getAnswer(i: number): ResponseAxis | { answer: null } {
    if (typeof assessment.responses[axis] === "undefined") {
      return { answer: null };
    }
    return assessment.responses[axis][numToQID(i)];
  }

  function onClick(i: number) {
    if (mode === "question") {
      history.push(`/questions/${assessment.id}/${axis}/${i}`);
    }
  }


  function EndSlot({ resp }: any) {
    if(mode === 'detail'){
      return (<div className={`text-color ${axis}`} slot="end">
        {answerNum2Con(resp.answer)}
      </div>)
    }
    return (
      <>
        {isNull(resp.answer) ? (
          <IonIcon
            style={{ opacity: ".3" }}
            icon={chevronForwardOutline}
            slot="end"
          />
        ) : (
          <IonIcon className="answered" icon={checkmarkSharp} slot="end" />
        )}
      </>
    );
  }

  let currGroup = "";
  let setGroup = false;
  return (
    <>
      {mode === "detail" && (
        <IonItem className={`ion-no-padding item-detail rindex-axis ${axis}`} lines="none">{AXIS[axis].name.pt}</IonItem>
      )}
      {questions[axis] &&
        Object.keys(questions[axis])
          .sort()
          .map((key: any, i: number) => {
            // key = qxx, i = x
            let item = questions[axis][key];

            setGroup = false;
            if (currGroup !== item.group && item.group.length) {
              currGroup = item.group;
              setGroup = true;
            }
            let resp: any = getAnswer(i);

            return (
              <div key={i} className={`rindex-questions-list ${mode==='detail'?' item-detail':''} ${axis}`}>
                {setGroup && (
                  <IonItemDivider>
                    <IonLabel>{`${currGroup}`}</IonLabel>
                  </IonItemDivider>
                )}
                <IonItem
                  onClick={() => onClick(i)}
                  className="ion-no-padding rindex-item"
                  lines={(resp?.notes && mode === 'detail') ? 'none' : 'full'}
                >
                  <IonLabel>
                    {item.code} {item.question.pt}
                  </IonLabel>
                  <EndSlot resp={resp} />
                </IonItem>
                {(resp?.notes && mode === 'detail') &&
                <IonItem className="ion-ion-no-padding">
                <div style={{fontSize: '.9em', lineHeight: '1.2em'}}>{resp?.notes}</div>
                </IonItem>}

              </div>
            );
          })}
    </>
  );
};

export default QuestionsList;
