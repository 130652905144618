import * as firebase from 'firebase/app'

// Typescript:
//   import * as firebase from 'firebase/app';
// import 'firebase/<PACKAGE>';


import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/database'
// import 'firebase/functions' // <- needed if using httpsCallable
import {useSelector} from "react-redux";
import {isLoaded} from "react-redux-firebase";
import React from "react";
import SplashScreen from "../components/SplashScreen/SplashScreen";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_apiKey,
  authDomain: process.env.REACT_APP_FB_authDomain,
  databaseURL: process.env.REACT_APP_FB_databaseURL,
  projectId: process.env.REACT_APP_FB_projectId,
  storageBucket: process.env.REACT_APP_FB_storageBucket,
  messagingSenderId: process.env.REACT_APP_FB_messagingSenderId,
  appId: process.env.REACT_APP_FB_appId,
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize other services on firebase instance
firebase.firestore(); // <- needed if using firestore

// firebase.functions(); // <- needed if using httpsCallable


export default firebase;

export const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
  // enableClaims: true // Get custom claims along with the profile
};


export function AuthIsLoaded({children}) {
  const auth = useSelector(state => state.firebase.auth);
  if (!isLoaded(auth)) return <SplashScreen />;
  return children
}
