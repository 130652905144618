import React from 'react';
import {useHistory} from 'react-router';

interface AxisBarProps {
  name: string;
  axis: string;
  percents: any;
  assessmentId: string;
}

const AxisBar = (props: AxisBarProps) => {
  const {name, axis, percents, assessmentId} = props;
  let percent: number = percents[axis].percent;
  let answered: number = percents[axis].answered;
  const history = useHistory();
  const width = percent < 5 ? 5 : percent;

  function onClick() {
    history.push(`/status/${assessmentId}/${axis}`);
  }

  return (
    <div className="rindex-axis-bar" onClick={onClick}>
      <div className="name">{name}</div>
      <div className={`bar ${axis}`}>
        <div className="width" style={{width: `${width}%`}}>
          <span>{answered}</span>
        </div>
      </div>
    </div>
  );
};

export default AxisBar;
