/**
 * Status das avaliações
 */
export enum Status {
  WAITING,// não começou a avaliação
  IN_PROGRESS,// avaliando
  FINISHED// já fechou a avaliação, não pode editar
}

/**
 * Axis
 * categorias de avaliação
 */
export enum Axis {
  RECONHECIMENTO = 'reconhecimento',
  RELACIONAMENTO = 'relacionamento',
  RELEVANCIA = 'relevancia',
  REPUTACAO = 'reputacao',
  RESILIENCIA = 'resiliencia',
}

export const AXIS: any = {
  reconhecimento: {name: {pt: 'Reconhecimento', en: 'Reconhecimento'}},
  relacionamento: {name: {pt: 'Relacionamento', en: 'Relacionamento'}},
  relevancia: {name: {pt: 'Relevância', en: 'Relevância'}},
  reputacao: {name: {pt: 'Reputação', en: 'Reputação'}},
  resiliencia: {name: {pt: 'Resiliência', en: 'Resiliência'}},
};

export function answerNum2Con(num: number){
// NV	NA	APP	AP	AGP	AI
// -1	0	  25	50	75	100
  if(num === -1){
    return 'NV'
  } else if(num === 0){
    return 'NA'
  } else if(num === 25){
    return 'APP'
  } else if(num === 50){
    return 'AP'
  } else if(num === 75){
    return 'AGP'
  } else if(num === 100){
    return 'AI'
  }
  return 'NA'
}

export interface AssessmentResponses {
  // reconhecimento: any;
  // relacionamento: any;
  // relevancia: any;
  // reputacao: any;
  // resiliencia: any;
  reconhecimento: ResponseAxis[];
  relacionamento: ResponseAxis[];
  relevancia: ResponseAxis[];
  reputacao: ResponseAxis[];
  resiliencia: ResponseAxis[];
}

export interface Question {
  code: string;
  group: string;
  question: {pt: string; en: string};
  external: boolean;
}

export interface ResponseAxis {
  code: string;
  group: string;
  answer: number | null;
  notes: string;
}

/**
 * Modelo de avaliação
 */
export interface Assessment {
  id: string;
  entity: { id: string, name: string };
  iniDate: number | null;
  endDate: number | null;
  auditor: { id: string, name: string };
  status: Status;
  notes: string;
  version: string;// v1
  responses: AssessmentResponses | any;
  score?: AssessmentScore
}

export interface AssessmentScore {
  reconhecimento: number;
  relacionamento: number;
  relevancia: number;
  reputacao: number;
  resiliencia: number;
  final: {
    points: number;
    concept: string;
  }
  url: string;
}

/**
 * Props da lista de avaliações
 */
export interface AssessmentListProps {
  assessments: Assessment[];
  textFilter?: string;
  statusFilter?: Status;
}

export interface AssessmentItemProps {
  assessment: Assessment;
}
