import {ReducerAction} from '../../types/Reducers.d';
import {Entity} from '../../types/Entities.d';
import {ENTITY_ACTION_TYPE} from '../actions/entityActions';
import {AUTH_ACTION_TYPE} from '../actions/authActions';

export const formPluginReducer = {
  // EntityForm
  entity: (state: any, action: ReducerAction<Entity>) => {

    if (action.type === ENTITY_ACTION_TYPE.LOAD) {
      return {
        ...state,
        values: action.payload
      };
    }
    return state;
  },

  // Profile
  profile: (state: any, action: ReducerAction<any>) => {

    if(action.type === AUTH_ACTION_TYPE.LOAD){
      return {
        ...state,
        values: action.payload
      }
    }

    return state;
  }
};
