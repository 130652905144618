import {useFirestoreConnect} from 'react-redux-firebase';
import {useSelector} from 'react-redux';
import {RootReducerType} from '../../../types/Reducers.d';
import useAuth from '../../User/hooks/useAuth';


function useMyAssessments(){
  useFirestoreConnect('assessments');
  const [isLogged, profile, auth] = useAuth();
  let assessments = useSelector((state: RootReducerType) => state.firestore.ordered.assessments)

  if(assessments){
    assessments = assessments.filter((assessment)=> assessment.auditor.id === auth.uid)
  }

  return assessments;
}

export default useMyAssessments;
