import {useEffect, useState} from 'react';
import {useHistory} from 'react-router';

export function useMenuRestrictions(without: string[]) {

  const history = useHistory();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {

    if (without.indexOf(history.location.pathname) !== -1) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
    // console.log(history.location.pathname);
    // console.log('disabled', disabled);

  }, [history.location.pathname]);

  return disabled;
}
