import {Dispatch} from 'redux';
import firebase from 'firebase/app';
import moment from 'moment';
import {DATE_FORMAT, EMAIL_ADMINS} from '../../support/helpers';
import {Profile, UserType} from '../../types/User.d';
import {Error} from '../../types/Reducers.d';
import axios, { AxiosResponse, AxiosError } from 'axios';
import Api from '../../support/api';


export const AUTH_ACTION_TYPE = {
  LOGIN: 'LOGIN_SUCCESS',
  LOGIN_ERR: 'LOGIN_ERROR',
  LOGOUT: 'LOGOUT_SUCCESS',
  CREATED: 'USER_CREATED',
  CREATED_ERR: 'USER_CREATED_ERROR',
  CODE_VALIDATE: 'CODE_VALIDATE',
  LOAD: 'LOAD_USER',
  UPDATE: 'USER_UPDATE'
};

interface Credentials {
  email: string;
  password: string;
  name?: string;
}

interface SocialCredentials {
  provider: string;
  type?: string;
  scopes?: string[];
}


interface UserForCreation {
  uid: string | undefined;
  name: string | undefined | null;
  avatar: string | null;
  email: string | null;
  phone: string | null;
  provider: string | undefined;
  type: UserType;
}

/**
 * Send reducer message to load profile to form reducer
 * @param callback
 */
export const loadCurrentUser = (callback?: () => void) => {
  return (dispatch: Dispatch, getState: any, getFirebase: any) => {
    dispatch({type: AUTH_ACTION_TYPE.LOAD, payload: getState().firebase.profile});
    if (typeof callback !== 'undefined') callback();
  };
};

/**
 * Do login via Firebase Social
 * @param credentials
 * @param callback
 */
export const signInWithSocial = (
  credentials: SocialCredentials,
  callback?: (success: boolean, userError: any | Error) => void
) => {
  return async (dispatch: Dispatch, getState: Function, getFirebase: any) => {

    credentials.type = credentials.type || 'popup';
// debugger;
    try {


      const resp: firebase.auth.UserCredential = await getFirebase().login(credentials);
      if (resp.user && resp.user.uid) {

        let userData = {
          uid: resp.user?.uid,
          name: resp.user?.displayName || 'anônimo',
          avatar: resp.user.photoURL || '',
          email: resp.user.email || '',
          phone: resp.user.phoneNumber || '',
          provider: resp.credential?.providerId || '',
          type: UserType.AUDITOR
        };

        const wasCreated: boolean = await createUser(getFirebase(), userData);

        /** Update admin */
        if (EMAIL_ADMINS.indexOf(String(resp.user?.email)) !== -1) {
          let data = {
            code: resp.user?.email,
            valid: true,
            rated_at: '0000-00-00 00:00:00',
          };
          await updateUserCodeStatus(getFirebase(), getState().firebase.auth.uid, data);
        }

        if (wasCreated) {
          Api.syncUser({
            uid: userData.uid,
            name: userData.name,
            avatar: userData.avatar,
            email: userData.email,
            phone: userData.phone,
            type: userData.type,
          });
        }
        dispatch({type: AUTH_ACTION_TYPE.LOGIN, payload: resp.user});
        if (typeof callback !== 'undefined') callback(true, resp.user);


      } else {

        let err: Error = {code: 'uid/not-found', message: 'UID do usuário não foi retornado.'};
        dispatch({type: AUTH_ACTION_TYPE.LOGIN_ERR, payload: err});
        if (typeof callback !== 'undefined') callback(false, err);

      }

    } catch (err) {
      dispatch({type: AUTH_ACTION_TYPE.LOGIN_ERR, payload: err});
      if (typeof callback !== 'undefined') callback(false, err);
    }

  };
};

/**
 * Update user profile
 * @param profile
 * @param callback
 */
export const updateProfile = (profile: { id: string; name: string; email: string; phone: string, occupation: number },
                              callback: (success: boolean) => void) => {
  return async (dispatch: Dispatch, getState: any, getFirebase: any) => {
    // update firebase
    const collection = getFirebase().firestore().collection('users');
    await collection.doc(profile.id).update(profile);

    dispatch({type: AUTH_ACTION_TYPE.UPDATE, payload: profile});

    Api.syncUser({
      uid: profile.id,
      name: profile.name,
      email: profile.email,
      phone: profile.phone,
      occupation: profile.occupation,
    });

    if (typeof callback !== 'undefined') callback(true);
  };

};

export const signInWithLoginAndPassword = (credentials: Credentials) => {
  return async (dispatch: any, getState: any, getFirebase: any) => {

    try {
      await getFirebase().auth().signInWithEmailAndPassword(
        credentials.email,
        credentials.password,
      );
    } catch (err) {
      dispatch({type: AUTH_ACTION_TYPE.LOGIN_ERR, payload: err});
    }

    dispatch({type: AUTH_ACTION_TYPE.LOGIN});

  };
};

export const signOut = () => {
  return (dispatch: any, getState: any, getFirebase: any) => {
    getFirebase().auth().signOut()
      .then((res: any) => {
        dispatch({type: AUTH_ACTION_TYPE.LOGOUT});
      });
  };
};

/**
 * Add user profile
 * @returns {Function}
 */
export const signUp = (newUser: Credentials) => {
  return (dispatch: any, getState: any, getFirebase: any) => {
    getFirebase().auth().createUserWithEmailAndPassword(
      newUser.email,
      newUser.password
    )
      .then((res: any) => {

        getFirebase().firestore()
          .collection('users')
          .doc(res.user.uid)// build id
          .set({
            name: newUser.name,
            email: newUser.email,
            createdAt: new Date()
          })
          .then(() => {
            dispatch({type: AUTH_ACTION_TYPE.CREATED});
          })
          .catch((err: any) => {
            dispatch({type: AUTH_ACTION_TYPE.CREATED_ERR, payload: err});
          });

      });
  };
};

/**
 * Check on API if user already was validated
 *
 * @param callback
 */
export const hasValidCode = (callback: (resp: { code: string | null; valid: boolean; rated_at: string }) => void) => {
  return async (dispatch: Dispatch, getState: any, getFirebase: any) => {

    let uid: string = getState().firebase.auth.uid;
    let profile: Profile = getState().firebase.profile;


    /** Validate admins */
    if (EMAIL_ADMINS.indexOf(profile.email) !== -1) {
      let data = {
        code: profile.email,
        valid: true,
        rated_at: '0000-00-00 00:00:00',
      };

      dispatch({
        type: AUTH_ACTION_TYPE.CODE_VALIDATE, payload: {
          success: true,
          msg: 'Admin',
          data: {...data}
        }
      });

      callback(data);
      return;
    }
    // end/ admins

    // if (profile && profile.code?.valid && profile.code?.code) {
    //   callback({
    //     code: profile.code.code,
    //     valid: profile.code.valid,
    //     rated_at: profile.code.rated_at,
    //   });
    // } else {


    // }

    const resp: AxiosResponse<any> = await Api.isCodeValid(uid);

    if(resp.data.code){
      await updateUserCodeStatus(getFirebase(), uid, {
        code: resp.data.code,
        valid: resp.data.valid,
        rated_at: resp.data.rated_at,
      });
    }


      callback({
        code: resp.data.code,
        valid: resp.data.valid,
        rated_at: resp.data.rated_at,
      });


  };
};


/**
 * Send code to start validation process
 * @param code
 * @param callback
 */
export const validateCode = (
  code: string,
  callback: (data: { success: boolean, msg: string, data: {} }) => void
) => {
  return async (dispatch: Dispatch, getState: any, getFirebase: any) => {

    let data: { success: boolean, msg: string, data: {} };
    // valida se string tem parâmetros mínimos
    if (code.length === 0) {
      setTimeout(() => {
        data = {
          success: false,
          msg: 'Código inválido',
          data: {}
        };
        dispatch({type: AUTH_ACTION_TYPE.CODE_VALIDATE, payload: data});
        if (typeof callback !== 'undefined') callback(data);
      }, 1000);
      return;
    }

    try {
      const resp: AxiosResponse<any> = await Api.validateCode(code);

      await updateUserCodeStatus(getFirebase(), getState().firebase.auth.uid, {
        code: resp.data.code,
        valid: resp.data.valid,
        rated_at: resp.data.rated_at,
      });

      data = {
        success: resp.data.valid,
        msg: resp.data.message,
        data: resp.data
      };

    } catch (err) {
      data = {
        success: false,
        msg: (axios.isAxiosError(err) && err?.message) || 'Erro ao validar código',
        data: {
          err
        }
      };
    }


    dispatch({type: AUTH_ACTION_TYPE.CODE_VALIDATE, payload: data});
    if (typeof callback !== 'undefined') callback(data);

  };
};

/**
 *
 * @param firebase
 * @param user
 * return Promise
 */
const createUser = (firebase: any, user: UserForCreation): Promise<any> => {

  return new Promise((resolve, reject) => {
    firebase.firestore().collection('users').doc(user.uid).get()
      .then((doc: firebase.firestore.DocumentSnapshot) => {

        if (!doc.exists || !doc.data()?.id) {
          firebase.firestore()
            .collection('users')
            .doc(user.uid)// build id
            .set({
              id: user.uid,
              name: user.name,
              email: user.email,
              phone: user.phone,
              avatar: user.avatar,
              provider: user.provider,
              type: user.type,
              createdAt: moment().format(DATE_FORMAT)
            }).then(() => {
            resolve(true);
          });
        } else {
          resolve(false);
        }
      });
  });

};

function updateUserCodeStatus(firebase: any, uid: string, code: any) {

  return firebase.firestore().collection('users')
    .doc(uid)
    .update({code: code});
}
