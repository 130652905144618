import React from 'react';
import {WrappedFieldProps} from 'redux-form';
import {IonInput, IonItem, IonLabel, IonText} from '@ionic/react';
import InputMask from 'react-input-mask'
interface CustomProps {
  mask?: any
}

type TextProps = WrappedFieldProps & CustomProps & any;

const Text: React.FC<TextProps> = (props: TextProps) => {
  const {input, meta, type, label, mask, placeholder, disabled} = props;

  function maskprops(){
    if(typeof mask === 'undefined')return null;
    if(typeof mask === 'string')return {mask};
    if(typeof mask === 'object')return mask;
  }

  return (
    <IonItem className="ion-no-padding">
      <IonLabel position="stacked">{label}</IonLabel>
      {mask
      ? (<InputMask className="native-input sc-ion-input-ios" {...input} placeholder={placeholder} disabled={disabled} {...maskprops()}/>)
      : (<IonInput {...input} placeholder={placeholder} disabled={disabled}/>)}


      {(meta.touched &&
        (meta.error && (<IonText className="form-error">{meta.error}</IonText>))
      )}
    </IonItem>
  );
};

export default Text;
