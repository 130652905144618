import React, { useCallback, useEffect, useState } from "react";
import {
  IonAlert,
  IonButton,
  IonCheckbox,
  IonFab,
  IonFabButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonPopover,
  IonTextarea,
  IonToast,
} from "@ionic/react";
import {
  chatboxOutline,
  chatboxEllipsesOutline,
  add,
  bookOutline,
} from "ionicons/icons";
import isNull from "lodash/isNull";

import { Question, ResponseAxis } from "../../../types/Assessments.d";
import { useDispatch } from "react-redux";
import Api from "../../../support/api";
import { manualUrl } from '../../../support/helpers';
import {
  updateAnswer,
  updateAnswerNote,
} from "../../../store/actions/assessmentActions";
import './SingleQuestion.scss'

interface SingleQuestionProps {
  question: Question;
  answer: ResponseAxis;
  axis: string;
  i: number; // index of this question
  assessmentId: string;
}

const SingleQuestion = ({
  question,
  answer,
  axis,
  i,
  assessmentId,
}: SingleQuestionProps) => {
  const _options = [
    {
      label: { pt: "Atende integralmente" },
      val: "100",
      isChecked: false,
      isDisabled: false,
    },
    {
      label: { pt: "Atende em grande parte" },
      val: "75",
      isChecked: false,
      isDisabled: false,
    },
    {
      label: { pt: "Atende em parte" },
      val: "50",
      isChecked: false,
      isDisabled: false,
    },
    {
      label: { pt: "Atende em pequena parte" },
      val: "25",
      isChecked: false,
      isDisabled: false,
    },
    {
      label: { pt: "Não atende" },
      val: "0",
      isChecked: false,
      isDisabled: false,
    },
    {
      label: { pt: "Não verificado e/ou não aplicável" },
      val: "-1",
      isChecked: false,
      isDisabled: false,
    },
  ];

  const disp = useDispatch();
  const [showNote, setShowNote] = useState(false);
  const [noteEvent, setNoteEvent] = useState<any>(null);
  const [noteValue, setNoteValue] = useState("");
  const [options, setOptions] = useState(parseOptions(_options, answer));
  const [currVal, setCurrVal] = useState<number | null>(null);
  const [toast, setToast] = useState(false);

  const RenderItems = useCallback(() => {}, []);

  useEffect(() => {
    setCurrVal(answer.answer);
    setNoteValue(answer.notes);
    // setOptions(parseOptions(options, answer));
  }, [answer]);

  function parseOptions(options: any[], answer: any) {
    let newOptions = [...options];
    newOptions.map((opt, i) => {
      if (!isNull(answer.answer)) {
        opt.isChecked = parseInt(opt.val) === answer.answer;
      }
      opt.isDisabled =
        answer.group.length === 0 && ["0", "100"].indexOf(opt.val) === -1;
      return opt;
    });
    return newOptions;
  }

  function NotePopOver({ isOpen, event }: any) {
    return (
      <>
        <IonAlert
          cssClass="-rindex-login-alert"
          backdropDismiss={false}
          isOpen={isOpen}
          onDidPresent={() => {
            setNoteValue(answer.notes);
          }}
          onDidDismiss={() => setShowNote(false)}
          header={'Notas'}
          inputs={[
            {
              name: "note",
              type: "textarea",
              value: noteValue,
              id: 'note-textarea',
              placeholder: "Deixe uma nota...",
            },
          ]}
          buttons={[
            {
              text: "CANCELAR",
            },
            {
              text: "SALVAR",
              role: "OK",
              cssClass: "primary",
              handler: ({ note }) => {
                setNoteValue(note);
                // console.log(assessmentId, axis, i, note);
                disp(
                  updateAnswerNote(
                    assessmentId,
                    axis,
                    i,
                    note,
                    (success: boolean, err: Error | undefined) => {
                      if (!success) setToast(true);
                    }
                  )
                );
              },
            },
          ]}
        />
      </>
    );
  }

  function handleClick(j: number) {
    // console.log("clicked");
    let optVal = parseInt(options[j].val);

    if (optVal === currVal) {
      return;
    }
    options.forEach((option, c) => {
      option.isChecked = c === j;
    });
    setOptions([...options]);

    setCurrVal(optVal);
    // console.log(assessmentId, axis, i, optVal);
    disp(
      updateAnswer(
        assessmentId,
        axis,
        i,
        optVal,
        (success: boolean, err: Error | undefined) => {
          if (!success) setToast(true);
        }
      )
    );
  }

  const Toast = ({ msg }: { msg: string }) => {
    return (
      <IonToast
        isOpen={toast}
        onDidDismiss={() => setToast(false)}
        message={msg}
        position="top"
        color="secondary"
        duration={3000}
      />
    );
  };

  return (
    <div className={axis}>
      <Toast
        msg={`Houve um problema para salvar avaliação. Verifique se existe conexão.`}
      />
      <h3 className="question">
        {question.code} {question.question.pt}
      </h3>
      {question.external && <p style={{opacity: .5}}>Item dependente de pesquisa externa</p>}

      <IonButton
        onClick={(e) => {
          setShowNote(true);
        }}
        className={`leave-note ${noteValue.length > 0 ? 'has-note' : ''}`}
        fill="clear"
      >
        <IonIcon slot="start" icon={chatboxOutline} />
        {noteValue.length > 0 ? 'Editar' : 'Deixar nota'}
         <span className="text-note-tip">{noteValue}</span>
      </IonButton>
      <NotePopOver event={noteEvent} isOpen={showNote} />
      <br />

      {options.map(({ label, val, isChecked, isDisabled }, j) => (
        <IonItem onClick={() => handleClick(j)} lines="none" key={j}>
          <IonLabel>{label.pt}</IonLabel>
          <IonCheckbox
            slot="start"
            value={val}
            checked={isChecked}
            disabled={isDisabled}
          />
        </IonItem>
      ))}
      <hr />
      <IonItem
        lines="none"
        onClick={() => {
          window.open(manualUrl(question), "_blank");
        }}
      >
        <IonIcon
          icon={bookOutline}
          slot="start"
          style={{ color: "var(--brand-grey)" }}
        />
        <IonLabel style={{ color: "var(--brand-grey)" }}>
          Manual da tática
        </IonLabel>
      </IonItem>
      {/* <pre>{JSON.stringify(options, null, 2)}</pre> */}
    </div>
  );
};

export default SingleQuestion;
