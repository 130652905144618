/**
 *
 */
export enum UserType {
  // 0, 1
  AUDITOR = 2,
  // 3, 4
  MANAGER = 5,
  // 6, 7
  ADMIN = 8
}

/**
 * Model of each Profile
 */
export interface Profile {
  id: string;
  name: string;
  email: string;
  avatar: string;
  phone: string;
  provider: string;
  type: UserType;
  createdAt: string;
  isEmpty: boolean;
  isLoaded: boolean;
  occupation?: number;
  code?: {code: string|null; valid: boolean; rated_at: string}
}

export type Occupation = {
  id: number,
  label: string,
}

export const OCCUPATIONS: Occupation[] = [
  {id: 1, label: 'Administração Corporativa'},
  {id: 2, label: 'Comunicação / Agência'},
  {id: 3, label: 'Comunicação / Profissional Liberal'},
  {id: 4, label: 'Compliance / Direito'},
  {id: 5, label: 'Consultoria Empresarial / Autônomo'},
  {id: 6, label: 'Gestão Corporativa / Estratégica'},
  {id: 7, label: 'Gestão de Processos / Sistemas de Gestão'},
  {id: 8, label: 'Financeira / Contábil'}
]
