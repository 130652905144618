import {
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../../components/NavBar";
import { Assessment } from "../../types/Assessments.d";
import { AssessmentPresenter } from "../../components/Assessment/AssessmentPresenter";
import { RootReducerType } from "../../types/Reducers.d";
import AxisNav from "../../components/Assessment/AxisNav";
import StatusHeader from "../../components/Assessment/StatusHeader";
import QuestionsSlider from "../../components/Assessment/Questions/QuestionsSlider";
import "./AssessmentsQuestions.scss";
import Loading from "../../components/UI/Loading";
import { fetchAssessment } from "../../store/actions/assessmentActions";
import { updateScore } from "../../store/actions/progressActions";
import Spinner from "../../components/UI/Spinner/Spinner";
import QuestionsNavContext from "./QuestionsNavContext";

type AssQuestionsProps = {} & RouteComponentProps<{
  assessmentId: string;
  axis: string;
  question: string;
}>;

const AssessmentsQuestions = (props: AssQuestionsProps) => {
  const disp = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [assessment, setAssessment] = useState<Assessment | null>(null);
  const axis = props.match.params.axis;
  const i = props.match.params.question;
  const assessmentId = props.match.params.assessmentId;
  const [index, setIndex] = useState(i);
  const slidesRef = useRef<any>(null)

  useEffect(() => {
    disp(
      fetchAssessment(assessmentId, (_assessment: Assessment | null) => {
        if (_assessment) {
          // console.log({ _assessment });

          disp(updateScore(_assessment));
          setAssessment(_assessment);
        } else {
          history.push(`/status/${assessmentId}/${axis}`);
        }
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
    );
  }, [axis, i]);


  function RenderQuestionsBody({
    assessment,
    axis,
    i,
  }: {
    assessment: Assessment;
    axis: string;
    i: string;
  }) {
    const assessmentPre = new AssessmentPresenter(assessment);

    useFirestoreConnect(`questions_${assessment.version}`);

    const questions = useSelector(
      (state: RootReducerType) =>
        state.firestore.data[`questions_${assessment.version}`]
    );

    const QuestionsMemoised = useMemo(() => {
      if (!questions) return;
      return (
        <QuestionsSlider
          assessmentId={assessment.id}
          questions={questions}
          assessment={assessmentPre}
          axis={axis}
          start={i}
        />
      );
    }, [questions]);

    if (!isLoaded(questions) || !assessment.responses) {
      return <Spinner />;
    }

    return (
      <>
        <QuestionsNavContext.Provider value={{ slidesRef }}>
          <StatusHeader assessment={assessmentPre} entity={assessment.entity} />
          <AxisNav
            baseRoute="questions"
            assessmentId={assessment.id}
            currentAxis={axis}
          />
          {QuestionsMemoised}
        </QuestionsNavContext.Provider>
      </>
    );
  }

  return (
    <IonPage>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle>Avaliando</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div data-comp-questions={true} className="content">
          <Loading isOpen={loading} />
          {assessment && (
            <RenderQuestionsBody assessment={assessment} axis={axis} i={i} />
          )}
        </div>
      </IonContent>
      <IonFooter>
        <NavBar />
      </IonFooter>
    </IonPage>
  );
};

export default AssessmentsQuestions;
