import React from 'react';
import {IonSpinner} from '@ionic/react';
import './Spinner.scss';

const Spinner = () => {
  return (
    <div data-comp-spinner={true}>
      <IonSpinner  name="crescent" />
    </div>
  );
};

export default Spinner;
