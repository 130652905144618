// import Page from './pages/Page';
import React, { lazy, Suspense, useEffect } from "react";
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupConfig,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";
import Menu from "./components/Menu";
import Login from "./pages/Login";
import About from "./pages/About";
import Assessments from "./pages/Assessments";
import AssessmentsAxes from "./pages/AssessmentsAxes";
import AssessmentsQuestions from "./pages/AssessmentsQuestions";
import AssessmentsStatus from "./pages/AssessmentsStatus";
import Auditors from "./pages/Auditors";
import Entities from "./pages/Entities";
// import Manual from './pages/Manual';
import Score from "./pages/Score";
import ScoreDetails from "./pages/ScoreDetails";
import Profile from "./pages/Profile";
import SplashScreen from "./components/SplashScreen";
import Help from "./pages/Help";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AllowIfNotAuthenticated } from "./components/User/hoc/AllowIfNotAuthenticated";
import { AllowIfAuthenticated } from "./components/User/hoc/AllowIfAuthenticated";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/global.scss";
import { useDispatch } from "react-redux";
import { loadConfig } from "./store/actions/configActions";
import { validateCode, hasValidCode } from './store/actions/authActions';
import { useFirebase } from 'react-redux-firebase';
import useAuth from "./components/User/hooks/useAuth";
import Tutorial from "./pages/Tutorial";

// Experimenting... lazy loading component
const Manual = lazy(() => import("./pages/Manual"));
// const _AddToHomeScreen = require('@ideasio/add-to-homescreen-react')
// const {AddToHomeScreen} = _AddToHomeScreen
/**
 * Set up global configuration
 * @link https://ionicframework.com/docs/react/config
 */
setupConfig({
  mode: "ios",
  animated: false,
  swipeBackEnabled: false,
});

const App: React.FC = () => {
  const disp = useDispatch();
  const [isLogged, profile, auth] = useAuth();
  useEffect(() => {
    disp(loadConfig());
  }, []);
  useEffect(()=>{
    if(isLogged){
      setTimeout(()=>{
        disp(hasValidCode(({code, valid, rated_at})=>{
          // console.log(code, valid, rated_at);
        }));
      }, 2000)
    }
  }, [isLogged])

  return (
    <IonApp>
        <IonReactRouter>
          <IonSplitPane contentId="main">
            <Menu />
            <Suspense fallback={<SplashScreen />}>
              <IonRouterOutlet id="main">
                <Route
                  path="/"
                  render={(props) => {
                    // TODO check if is logged in
                    return <Redirect to="/assessments" />;
                  }}
                  exact={true}
                />
                <Route path="/login" component={Login} exact={true} />
                <Route path="/about" component={About} exact={true} />
                <Route
                  path="/assessments"
                  component={AllowIfAuthenticated(Assessments)}
                  exact={true}
                />
                <Route
                  path="/axes/:assessmentId"
                  component={AssessmentsAxes}
                  exact={true}
                />
                <Route
                  path="/status/:assessmentId/:axis"
                  component={AssessmentsStatus}
                  exact={true}
                />
                <Route
                  path="/questions/:assessmentId/:axis/:question?"
                  component={AssessmentsQuestions}
                  exact={true}
                />
                <Route
                  path="/score/:assessmentId"
                  component={Score}
                  exact={true}
                />
                <Route
                  path="/scoredetails/:assessmentId"
                  component={ScoreDetails}
                  exact={true}
                />
                <Route path="/auditors" component={Auditors} exact={true} />
                <Route
                  path="/entities"
                  component={AllowIfAuthenticated(Entities)}
                  exact={true}
                />
                <Route
                  path="/manual"
                  component={AllowIfAuthenticated(Manual)}
                  exact={true}
                />
                <Route
                  path="/profile"
                  component={AllowIfAuthenticated(Profile)}
                  exact={true}
                />
                <Route path="/help" component={Help} exact={true} />
                <Route path="/tutorial" component={Tutorial} exact={true} />
              </IonRouterOutlet>
            </Suspense>
          </IonSplitPane>
        </IonReactRouter>
    </IonApp>
  );
};

export default App;
