import {combineReducers} from 'redux'
import {firebaseReducer} from 'react-redux-firebase'
import {firestoreReducer} from 'redux-firestore'
import {reducer as reducerForm} from "redux-form";
import EntityReducer from "./entityReducer";
import AuthReducer from "./authReducer";
import ProgressReducer from "./progressReducer";
import {formPluginReducer} from "./formReducer";
import ConfigReducer from "./configReducer";



// Add firebase to reducers
export default combineReducers({
  entity: EntityReducer,
  auth: AuthReducer,
  config: ConfigReducer,
  progress: ProgressReducer,
  form: reducerForm.plugin(formPluginReducer),
  firebase: firebaseReducer,
  firestore: firestoreReducer // <- needed if using firestore
})
