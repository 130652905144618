import React, {useEffect, useState} from 'react';
import {IonButton, IonList, IonToast} from '@ionic/react';
import {Field, FormErrors, InjectedFormProps, reduxForm} from 'redux-form';
import Text from '../../components/UI/FormInputs/Text';
import {loadCurrentUser, updateProfile} from '../../store/actions/authActions';
import useAuth from '../../components/User/hooks/useAuth';
import {useDispatch} from 'react-redux';
import {UserPresenter} from '../../components/User/UserPresenter';
import { Profile as ProfileModel, OCCUPATIONS } from '../../types/User.d';
import {Dispatch} from 'redux';
import Select from '../../components/UI/FormInputs/Select';

interface ProfileFormProps {
  user: UserPresenter
}

type MyFormProps = InjectedFormProps<{}, ProfileFormProps, FormErrors> & ProfileFormProps;


const ProfileForm = ({user, handleSubmit}: MyFormProps) => {

  const disp = useDispatch();
  const [isLogged, profile] = useAuth();
  const [toast, setToast] = useState<boolean>(false);
  const [toastMsg, setToastMsg] = useState<string>('');
  const [disableForm, setDisableform] = useState<boolean>(false);

  useEffect(()=>{
    disp(loadCurrentUser())
    user.hasValidCode() || setDisableform(true)
  }, [])

  function onSubmit(values: any) {
    let data = {
      id: profile.id,
      name: values.name,
      email: values.email,
      phone: values.phone,
      occupation: values.occupation,
    }
    disp(updateProfile(data, (success: boolean)=>{
      setToastMsg('Perfil atualizado');
      setToast(true)
    }))
  }

  const Toast = () => {
    return (
      <IonToast
        isOpen={toast}
        onDidDismiss={() => setToast(false)}
        message={toastMsg}
        position="top"
        color="success"
        duration={3000}
      />
    );
  };

  return (
    <>
      <Toast/>
      <form onSubmit={handleSubmit(onSubmit)}>
        <IonList>
          <Field name="name" type="text" component={Text}
                 label="Nome completo" disabled={disableForm}/>
          <Field name="email" type="text" component={Text}
                 label="E-mail" disabled={disableForm}/>
          <Field name="phone" type="text" component={Text}
                 label="Telefone" placeholder="(xx) xxxxx-xxxx" disabled={disableForm}/>
          <Field name="occupation" label="Ocupação" placeholder="Ocupação" component={Select} options={OCCUPATIONS.map(occ => {
            return {value: occ.id, label: occ.label}
          })} />
        </IonList>
        <div className="ion-padding-vertical">
          <IonButton type="submit" className="" expand="block"
                     disabled={disableForm}>ATUALIZAR PERFIL</IonButton>
        </div>
      </form>
    </>
  );
};


const asyncValidate = async (values: ProfileModel, dispatch: Dispatch, props: ProfileFormProps, fieldString: string) => {
  // console.log(fieldString, values);

  if (!fieldString && !values.name) {
    throw {name: `Nome completo é obrigatório`};
  }
  if (values.name.split(' ').length < 2) {
    throw {name: `Nome completo é obrigatório`};
  }
  if (!fieldString &&
    (!values.email || values.email.indexOf('@') === -1)) {
    throw {email: `E-mail é obrigatório`};
  }
  let phoneNumbers = values.phone.replace(/\D/g, '');
  // IF exists, must be formated as (xx) xxxxx-xxxx
  if(phoneNumbers && phoneNumbers.length < 11){
    throw {phone: 'Formato do telefone incorreto: (xx) xxxxx-xxxx'}
  }
  if (!values.occupation) {
    throw {name: `Nome completo é obrigatório`};
  }
};

export default reduxForm<{}, ProfileFormProps, FormErrors>({
  form: 'profile',
  enableReinitialize: true,
  // validate,
  asyncValidate,
  asyncBlurFields: [],
  persistentSubmitErrors: true
})(ProfileForm);

