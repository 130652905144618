import moment from 'moment';
import isNull from 'lodash/isNull';
import { Assessment, Status, AssessmentScore, AssessmentResponses, AXIS } from '../../types/Assessments.d';


export class AssessmentPresenter implements Assessment {
  id: string = '';
  entity: { id: string, name: string } = {id: '', name: ''};
  iniDate: number | null = null;
  endDate: number | null = null;
  auditor: { id: string, name: string } = {id: '', name: ''};
  status: Status = Status.WAITING;
  notes: string = '';
  version: string = 'v1';
  responses: AssessmentResponses | any = {};
  score?: AssessmentScore = undefined;

  constructor(assessment: Assessment) {
    Object.assign(this, assessment);
  }

  loaded(): boolean {
    return !!this.id;
  }

  isInProgress(): boolean {
    return this.status === Status.IN_PROGRESS;
  }

  isWaiting(): boolean {
    return this.status === Status.WAITING;
  }

  isFinished(): boolean {
    return this.status === Status.FINISHED;
  }

  /**
   * When every answers were done
   */
  isReadyToFinish(): boolean {
    const keys = Object.keys(this.responses);
    let isReady: boolean = true;
    // loop thought each axis
    for (let k = 0; k < keys.length; k++) {
      let axisKey = keys[k];
      let currAxis = Object.values(this.responses[axisKey]);
      if (!isReady) break;// prevent to keep looping others axes
      // loop though each answer on axis
      for (let a = 0; a < currAxis.length; a++) {
        let response: any = currAxis[a];
        if (isNull(response.answer)) {
          isReady = false;
          break;
        }
      }
    }
    return isReady;
  }

  getAxesPercent(): Object {
    const keys = Object.keys(this.responses);
    let output: any = {};
    // loop thought each axis
    keys.forEach(axisKey => {
      let total: number = Object.values(this.responses[axisKey]).length;
      let alreadyAnswered: number = 0;
      // loop thought each question response
      Object.values(this.responses[axisKey]).forEach((response: any) => {
        if (!isNull(response.answer)) alreadyAnswered++;
      });

      output[axisKey] = {
        total,
        answered: alreadyAnswered,
        percent: Math.round((alreadyAnswered * 100) / total)
      };
    });
    // console.info('getAxesPercent', this.id, output);
    return output;
  }

  getAxesScore(): Object {
    const keys = Object.keys(AXIS);
    let output: any = {};
    // loop thought each axis
    keys.forEach(axisKey => {
      let total: number = Object.values(this.responses[axisKey]).length;
      let sum: number = 0;
      let alreadyAnswered: number = 0;
      // loop thought each question response
      Object.values(this.responses[axisKey]).forEach((response: any) => {
        if (!isNull(response.answer)) {
          alreadyAnswered++;
          sum += response.answer;
        }
      });
      output[axisKey] = {
        total,
        answered: alreadyAnswered,
        score: sum ? (sum / alreadyAnswered) : 0
      };
    });
    // console.info('getAxesScore', this.id, output);
    return output;
  }

  /**
   * Do the math
   */
  countFinalScore(): number {
    const scores: any = this.getAxesScore();
    const division: number = Object.keys(scores).length;
    let count: number = 0;
    Object.keys(scores).forEach(axisKey => {
      count += scores[axisKey].score;
    });
    let result = count / division;
    return parseFloat(result.toFixed(2));
  }

  /**
   * Loop each axis and get the letter fot each
   */
  countFinalConcept(): string[] {
    const scores: any = this.getAxesScore();
    let concept: string[] = [];
    Object.keys(scores).forEach(axisKey => {
      concept.push(this.convertNumberToLetter(scores[axisKey].score));
    });
    return concept;
  }

  convertNumberToLetter(num: number): string {
    let letter = 'E';
    if (num === 100) letter = 'A';
    else if (num >= 97.51) letter = 'B';
    else if (num >= 95.01) letter = 'C';
    else if (num >= 92.51) letter = 'D';
    return  letter;
  }

  finalScore(force: boolean = false) {
    if (force) return this.countFinalScore();
    return this.score?.final.points;
  }

  finalConcept() {
    return this.score?.final.concept;
  }

  iniDateOnly(): string {
    return this.iniDate ? moment(this.iniDate).format('DD/MM/YYYY') : '';
  }

  iniHourOnly(): string {
    return this.iniDate ? moment(this.iniDate).format('HH:mm') : '';
  }

  endDateOnly(): string {
    return this.endDate ? moment(this.endDate).format('DD/MM/YYYY') : '';
  }

  endHourOnly(): string {
    return this.endDate ? moment(this.endDate).format('HH:mm') : '';
  }
}
