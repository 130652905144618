import React, { useEffect, useState} from 'react';
import {arraySearch} from '../../support/helpers';
import EntityItem from './EntityItem';
import {Entity, EntityListProps} from '../../types/Entities.d';
import {IonButton} from '@ionic/react';


const EntityList: React.FC<EntityListProps> = (props: EntityListProps) => {

  const [filtered, setFiltered] = useState<Entity[]>(props.entities);

  useEffect(()=>{
    if (props.textFilter && props.textFilter.length) {
      setFiltered(arraySearch(props.entities, 'name', props.textFilter));
    } else {
      setFiltered(props.entities);
    }
  }, [props.textFilter, props.entities])


  function onClick(entity: Entity){
    props.itemClicked(entity);
  }

  function emptyMessage(){
    return props.textFilter?.length === 0
      ? (<>
        <p style={{marginBottom: 12}}>Você ainda não tem nenhuma entidade registrada.</p>
        <p><IonButton onClick={props.addEntity} size="small">Adicionar entidade</IonButton></p>
      </>)
      : 'Nenhum resultado para o termo.'
  }

  return (
    <>
      {filtered.length ? (
        filtered.map((entity) => {
          return <EntityItem onClick={()=> onClick(entity)} key={entity.id} entity={entity}/>;
        })
      ) : (emptyMessage())}
    </>
  );
};

export default EntityList;
