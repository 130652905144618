import React, { useEffect, useState} from 'react';
import {Assessment, AssessmentListProps} from '../../types/Assessments.d';
import AssessmentItem from './AssessmentItem';
import {arraySearch} from '../../support/helpers';

type MyProps = AssessmentListProps;

const AssessmentsList: React.FC<MyProps> = (props: MyProps) => {

  const [filtered, setFiltered] = useState<Assessment[]>(props.assessments);


  useEffect(()=>{
    if (props.textFilter && props.textFilter.length) {
      setFiltered(arraySearch(props.assessments, 'entity.name', props.textFilter));
    }
  }, [props.textFilter, props.assessments])


  return (
    <>
      {filtered.length ? (
        filtered.map((assessment) => {
          return <AssessmentItem key={assessment.id} assessment={assessment}/>;
        })
      ) : ('Nenhum resultado para o termo.')}
    </>
  );
};

export default AssessmentsList;
