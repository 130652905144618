import {
  IonCard,
  IonCardContent,
  IonContent,
  IonFab,
  IonFabButton,
  IonFooter,
  IonHeader, IonIcon, IonItem, IonList,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import React, {useEffect, useState} from 'react';
import {add} from 'ionicons/icons';

import './Entities.scss';
import NavBar from '../../components/NavBar';
import {Entity} from '../../types/Entities.d';
import EntityForm from '../../components/Entity/EntityForm';
import EntityList from '../../components/Entity/EntityList';
import useMyEntities from '../../components/Entity/hooks/useMyEntities';
import useAuth from '../../components/User/hooks/useAuth';
import {UserPresenter} from '../../components/User/UserPresenter';
import AlertCodeStatus from '../../components/UI/Alert/AlertCodeStatus';
import AlertEntityCreated from '../../components/Entity/AlertEntityCreated';

const Entities = () => {

  const [searchText, setSearchText] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedEntity, setSelectedEntity] = useState<any>(null);
  const [alertCreated, setAlertCreated] = useState(false);
  const [user, setUser] = useState<UserPresenter>();
  const entities = useMyEntities();
  const [isLogged, profile, auth] = useAuth();

  useEffect(()=>{
    setUser(new UserPresenter(profile))
  }, [profile]);

  function openForm(entity: Entity | null) {
    setSelectedEntity(entity);
    setOpenModal(true);
  }

  function closeModal(isCreation: boolean) {
    if(isCreation){
      setAlertCreated(true)
    }
    setSelectedEntity(null);
    setOpenModal(false);
  }


  return (
    <IonPage>
      <IonHeader collapse="condense">
        <IonToolbar>
          <IonTitle>Entidades</IonTitle>
        </IonToolbar>
        <IonToolbar>
          { user?.hasValidCode()
            && (<IonSearchbar
            value={searchText}
            debounce={500}
            onIonChange={e => setSearchText(e.detail.value!)} animated/>) }
          <AlertCodeStatus user={user}/>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        {/* Botão para adicionar entidade */}
        { user?.hasValidCode() && (
          <>
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton onClick={() => openForm(null)}><IonIcon className="icon-stronger" icon={add}/></IonFabButton>
          </IonFab>
          <EntityForm selected={selectedEntity} isOpen={openModal} dismiss={closeModal}/>
          <AlertEntityCreated isOpen={alertCreated} onDismiss={()=>{setAlertCreated(false)}}/>
          </>
        )}

        <div data-comp-entities={true} className="content">

          <IonCard className="card__first">
            <IonCardContent>
              {/*-- List of Sliding Items --*/}
              <IonList>
                {entities && (
                  <EntityList
                    addEntity={()=>openForm(null)}
                    textFilter={searchText}
                    entities={entities}
                    itemClicked={openForm}/>
                )}
              </IonList>
            </IonCardContent>
          </IonCard>

        </div>

      </IonContent>
      <IonFooter>
        <NavBar/>
      </IonFooter>
    </IonPage>
  );
};

export default Entities;
