import {Entity} from '../../types/Entities.d';
import mockdata from '../../components/Entity/entities-mock-data';
import {ENTITY_ACTION_TYPE} from '../actions/entityActions';
import {ReducerAction} from '../../types/Reducers.d';

const initState: Entity[] = mockdata;

const EntityReducer = (state: {} = initState, action: ReducerAction<Entity>) => {

  // if (action.type === ENTITY_ACTION_TYPE.LOAD) {
  //
  //   return {
  //     ...state,
  //     posts: newPosts
  //   };
  // }

  return state;
};
export default EntityReducer;

