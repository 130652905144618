import React from 'react';
import {useHistory} from 'react-router';
import {IonAlert} from '@ionic/react';

export function AlertWelcome(props: any) {

  const history = useHistory();

  function didPresent() {

  }

  return (
    <IonAlert
      cssClass="rindex-login-alert"
      backdropDismiss={false}
      isOpen={props.isOpen}
      onDidPresent={didPresent}
      onDidDismiss={() => props.onDismiss()}
      header={'Seja bem-vindo'}
      subHeader={`Apenas auditores certificados podem utilizar o aplicativo.`}
      message={`<p>Aguarde a validação como auditor para utilizar todos os recursos do aplicativo.</p>`}
      buttons={[
        {
          text: 'AJUDA',
          cssClass: 'secondary',
          handler: () => {
            history.push('/help');
          }
        }, {
          text: 'ENTRAR',
          role: 'OK',
          cssClass: 'primary',
          handler: () => {
            history.push('/assessments');
          }
        },
      ]}
    />
  );
}
