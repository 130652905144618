import React from 'react';
import {
  IonContent,
  IonPage,
} from '@ionic/react';
import {Link} from 'react-router-dom';
import Logo from '../../components/Logo';
import LoginButtons from '../../components/LoginButtons';
import './Login.scss';
import { useSelector } from 'react-redux';
import { RootReducerType } from '../../types/Reducers.d';

// type LoginProps =

const Login = () => {

  const version = useSelector((s:RootReducerType) => s.config.app.version)

  return (
    <IonPage data-comp-login={true}>
      <IonContent className="ion-padding">
        <div className="wrapper">
          <div className="login--logo">
            <Logo/>
          </div>
          <LoginButtons/>
          <Link className="login--footer" to="/about">
            Sobre o ÍNDICE
          </Link>
          <div className="login--note">{version}</div>
        </div>
      </IonContent>
    </IonPage>
  );
};
export default Login;
