import React, {useState} from 'react';
import {IonButton, IonIcon, IonPopover} from '@ionic/react';
import {informationCircleOutline, reloadCircleOutline} from 'ionicons/icons';
import {isLoaded} from 'react-redux-firebase';
import useEntity from '../Entity/hooks/useEntity';
import {Entity} from '../../types/Entities.d';
import {AssessmentPresenter} from './AssessmentPresenter';
import {AXIS} from '../../types/Assessments.d';

interface EntityInfoProps {
  entityId: string;
  assessment: AssessmentPresenter;
}

const EntityInfo = (props: EntityInfoProps) => {

  const [showPopover, setShowPopover] = useState(false);
  const [popEvent, setPopEvent] = useState<React.MouseEvent<HTMLIonButtonElement>>(undefined!);
  const entity: Entity | undefined = useEntity(props.entityId);

  if (!isLoaded(entity)) {
    return <IonIcon size="large" icon={reloadCircleOutline}/>;
  }

  function PopOver({isOpen, event, entity, assessment}: any) {

    return (
      <IonPopover
        cssClass="rindex-entity-info"
        event={event}
        isOpen={isOpen}
        onDidDismiss={e => setShowPopover(false)}
      >
        <p><strong>{entity.name}</strong></p>
        <p className="note">{entity.cnpj}</p>
        <p className="note">Início em {assessment.iniDateOnly()} às {assessment.iniHourOnly()} <br/>
          Por {assessment.auditor.name}</p>
      </IonPopover>
    );
  }

  return (<>
    <IonButton onClick={(e) => {
      e.persist();
      e && setPopEvent(e);
      setShowPopover(true);
    }} color="dark" className="info" size="small" fill="clear">
      <IonIcon size="large" icon={informationCircleOutline}/>
    </IonButton>
    <PopOver event={popEvent} isOpen={showPopover} entity={entity} assessment={props.assessment}/>
  </>);
};

export default EntityInfo;
