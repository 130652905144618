/* eslint-disable no-throw-literal */
import React, { useEffect, useState } from "react";
import {
  IonAvatar,
  IonButton,
  IonChip,
  IonContent,
  IonFooter,
  IonHeader,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { Field, FormErrors, InjectedFormProps, reduxForm } from "redux-form";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import NavBar from "../../components/NavBar";
import {
  hasValidCode,
  validateCode,
} from "../../store/actions/authActions";
import {
  createAssessment,
  rebuildResponses,
} from "../../store/actions/assessmentActions";
import useAuth from "../../components/User/hooks/useAuth";
import { UserPresenter } from "../../components/User/UserPresenter";
import Spinner from "../../components/UI/Spinner/Spinner";
import { useHistory } from "react-router-dom";
import { useFirebase } from "react-redux-firebase";
import ProfileForm from "./ProfileForm";
import "./Profile.scss";
import { AlertCode } from "../../components/UI/Alert/AlertCode";

interface ProfileProps {}

const Profile = (props: ProfileProps) => {
  const firebase = useFirebase();
  const disp = useDispatch();
  const history = useHistory();
  const [showCode, setShowCode] = useState(false);
  const [isLogged, profile, auth] = useAuth();

  const [toast, setToast] = useState({show: false, msg: '', variation: 'secondary'});

  const [user, setUser] = useState<UserPresenter | null>(null);

  useEffect(() => {
    setUser(new UserPresenter(profile));
  }, [profile]);

  return (
    <IonPage>
      <IonContent>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Perfil</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div data-comp-profile={true} className="content ion-padding">
          {user ? (
            <div>
              <IonChip color={user.hasValidCode() ? `success` : `danger`}>
                {profile?.avatar && (
                  <IonAvatar>
                    <img src={profile.avatar} />
                  </IonAvatar>
                )}
                <IonLabel>
                  {user.hasValidCode()
                    ? user.getCode()
                    : `${user.getCode()} (não validado)`}
                </IonLabel>
              </IonChip>
              {!user.hasValidCode() && (
                <IonButton
                  shape="round"
                  fill="outline"
                  color="danger"
                  onClick={() => setShowCode(true)}
                >
                  validar
                </IonButton>
              )}
            </div>
          ) : (
            <Spinner />
          )}

          {!user?.hasValidCode() && (
            <>
              <p>
                Para editar seu perfil e fazer avaliações você precisa validar
                seu código de auditor.
              </p>
              <p>
                Clique em "validar", entre com seu código e aguarde até que seja
                verificado.
              </p>
              <p>
                <IonButton
                  onClick={() => {
                    firebase.logout();
                  }}
                >
                  Logout
                </IonButton>
                <IonButton
                  fill="outline"
                  onClick={() => {
                    history.push("/help");
                  }}
                >
                  Ajuda
                </IonButton>
              </p>
            </>
          )}

          {user && user.hasValidCode() && <ProfileForm user={user} />}

          <AlertCode
            isOpen={showCode}
            onDismiss={(payload: any) => {
              console.log({ payload});
              setTimeout(()=>{
                setToast({show: true, msg: "Aguarde validação.", variation: 'secondary'})
              }, 2000)
            if(payload){

            }
              setShowCode(false);
            }}
            showWelcome={(payload: any) => {
              // console.log({ payload });
              // setShowCode(false);
              //  setShowWelcome(true);
            }}
          />


          <IonToast
            isOpen={toast.show}
            onDidDismiss={() => setToast({...toast, show: false})}
            message={toast.msg}
            position="top"
            color={toast.variation}
            duration={3000}
          />

          {auth.email === "web@conceito-online.com.br" && (
            <div style={{ border: "solid 1px red", padding: 20 }}>
              <IonButton
                color="dark"
                size="small"
                onClick={() => {
                  disp(
                    hasValidCode((resp: any) => {
                      console.log("respondeu callback", resp);
                    })
                  );
                  // Api.isCodeValid().then((r: AxiosResponse)=>{
                  //   console.log(r);
                  // })
                }}
              >
                Check user code
              </IonButton>

              <IonButton
                color="dark"
                size="small"
                onClick={() => {
                  disp(
                    validateCode("abc", (data) => {
                      console.log(data);
                    })
                  );
                  // Api.validateCode('123').then((r: AxiosResponse)=>{
                  //   console.log(r);
                  // })
                }}
              >
                Validate new code
              </IonButton>

              <IonButton
                color="dark"
                size="small"
                onClick={() => {
                  disp(
                    createAssessment({
                      id: "1",
                      name: "Entidade 1",
                      cnpj: "1",
                      ownerName: "",
                      ownerEmail: "",
                      assessmentId: [],
                      auditor: "",
                      city:"",
                      state:"",
                      size:"",
                      type:"",
                      segment:"",
                    })
                  );
                }}
              >
                Cria nova avaliação
              </IonButton>

              <IonButton
                color="dark"
                size="small"
                onClick={() => {
                  disp(rebuildResponses("IaPL6K46ur4HRWeE1RfY"));
                }}
              >
                Refaz respostas
              </IonButton>
            </div>
          )}
        </div>
      </IonContent>
      <IonFooter>
        <NavBar />
      </IonFooter>
    </IonPage>
  );
};

export default Profile;
