import { deburr } from "lodash";
import { Axis, AXIS, Question } from "../types/Assessments.d";
import Api from "./api";

export const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const EMAIL_ADMINS = [
  "m.machado.neto@gmail.com",
  "web@conceito-online.com.br",
  "debug@conceito-online.com.br",
  "luis@conceito-online.com.br",
  "criacao@conceito-online.com.br",
  "contato@conceito-online.com.br",
];

/**
 * Filtra um item do objecto
 * TODO Implementar filtro por mais de um item
 * @param collection Array<{}>
 * @param key
 * @param searchterm
 */
export function arraySearch(
  collection: Array<any>,
  key: string,
  searchterm: string
): Array<any> {
  let txt = deburr(searchterm.toLowerCase());
  return collection.filter((item: any) => {
    return (
      deburr(findObjItemWithDotNotation(item, key).toLowerCase()).indexOf(
        txt
      ) !== -1
    );
  });
}

function findObjItemWithDotNotation(object: any, selector: string): string {
  let tpSel = selector.split(".").length === 2 ? "deep" : "flat";
  if (tpSel === "deep") {
    let parts = selector.split(".");
    return object[parts[0]][parts[1]];
  }

  return object[selector];
}

/**
 * Return current axis
 * @param axis
 */
export function getAxis(axis: string): { key: string; name: string } {
  return { key: axis, name: AXIS[axis]?.name?.pt };
}

/**
 * Return the previous axis
 * @param axis
 */
export function prevAxis(axis: string): { key: string; name: string } {
  let values: string[] = Object.values(Axis);
  let ttl = values.length;
  let currentKey = _currentAxisIndex(axis);
  let prev = "";

  if (currentKey === 0) {
    prev = values[ttl - 1];
  } else {
    prev = values[currentKey - 1];
  }

  return { key: prev, name: AXIS[prev].name.pt };
}

/**
 * Return the next axis
 * @param axis
 */
export function nextAxis(axis: string): { key: string; name: string } {
  let values: string[] = Object.values(Axis);
  let ttl = values.length;
  let currentKey = _currentAxisIndex(axis);
  let next: any = "";

  if (currentKey === ttl - 1) {
    next = values[0];
  } else {
    next = values[currentKey + 1];
  }

  return { key: next, name: AXIS[next].name.pt };
}

/**
 * Return a key of an question based on a number
 * @param i
 * return q00, q17, q39
 */
export function numToQID(i: number | string): string {
  return String(i).length === 1 ? `q0${i}` : `q${i}`;
}

export function progressFinished(score: any): boolean {
  const keys = Object.keys(score);
  let isReady: boolean = true;
  // loop thought each axis
  for (let k = 0; k < keys.length; k++) {
    let axis = score[keys[k]];
    if (axis.total !== axis.answered) {
      isReady = false;
      break;
    }
  }
  return isReady;
}

export function manualUrl(question: Question) {
  return `${Api.getWebUrl()}/docs/v2/${question.code}.pdf`;
}

function _currentAxisIndex(axis: string) {
  let array: string[] = Object.values(Axis);
  let ttl = array.length;
  for (let i = 0; i < ttl; i++) {
    if (axis === array[i]) {
      return i;
    }
  }
  return 0;
}

export function getEstadosBrasileiros(): { value: string; label: string }[] {
  const ufs: any = {
    AC: "Acre",
    AL: "Alagoas",
    AP: "Amapá",
    AM: "Amazonas",
    BA: "Bahia",
    CE: "Ceará",
    DF: "Distrito Federal",
    ES: "Espírito Santo",
    GO: "Goiás",
    MA: "Maranhão",
    MT: "Mato Grosso",
    MS: "Mato Grosso do Sul",
    MG: "Minas Gerais",
    PA: "Pará",
    PB: "Paraíba",
    PR: "Paraná",
    PE: "Pernambuco",
    PI: "Piauí",
    RJ: "Rio de Janeiro",
    RN: "Rio Grande do Norte",
    RS: "Rio Grande do Sul",
    RO: "Rondônia",
    RR: "Roraima",
    SC: "Santa Catarina",
    SP: "São Paulo",
    SE: "Sergipe",
    TO: "Tocantins",
  };
  let options: any = [];
  Object.keys(ufs).map((uf) => {
    options.push({ value: uf, label: ufs[uf] });
  });

  return options;
}

export function getPortes(): { value: string; label: string }[] {
  const data: any = {
    "0 a 150": "0 a 150 colaboradores",
    "150 a 250": "150 a 250 colaboradores",
    "250 a 350": "250 a 350 colaboradores",
    "350 a 450": "350 a 450 colaboradores",
    "500 a 1.000": "500 a 1.000 colaboradores",
    "1.000 a 3.000": "1.000 a 3.000 colaboradores",
    "Mais de 3.000": "Mais de 3.000 colaboradores",
  };
  let options: any = [];
  Object.keys(data).map((k) => {
    options.push({ value: k, label: data[k] });
  });

  return options;
}

export function getTiposSociedade(): { value: string; label: string }[] {
  const data: any = {
    "Sociedade Anônima": "Sociedade Anônima",
    "Sociedade Limitada": "Sociedade Limitada",
    "Sociedade Simples": "Sociedade Simples",
    "Sociedade Cooperativa": "Sociedade Cooperativa",
    "MEI - Microempreendedor Individual": "MEI - Microempreendedor Individual",
    "Empresário Individual": "Empresário Individual",
    "EIRELI": "EIRELI",
    "Outras": "Outras",
  };
  let options: any = [];
  Object.keys(data).map((k) => {
    options.push({ value: k, label: data[k] });
  });

  return options;
}

export function getSegmentos(): { value: string; label: string }[] {
  const data: any = {
"Administratação": "Administratação",
"Advocacia": "Advocacia",
"Aeroespacial": "Aeroespacial",
"Alimentícia": "Alimentícia",
"Atacado": "Atacado",
"Autarquias": "Autarquias",
"Automobilística": "Automobilística",
"Bancos e Investidoras": "Bancos e Investidoras",
"Beleza e Estética": "Beleza e Estética",
"Comércio": "Comércio",
"Comunicação": "Comunicação",
"Concessões Públicas": "Concessões Públicas",
"Construção Civil": "Construção Civil",
"Cultura e Artes": "Cultura e Artes",
"Distribuidoras": "Distribuidoras",
"Educação": "Educação",
"Eletrônicos": "Eletrônicos",
"Embalagens": "Embalagens",
"Eportação": "Eportação",
"Esportes": "Esportes",
"Eventos": "Eventos",
"Facliities": "Facliities",
"Farmacêutica": "Farmacêutica",
"Finanças e Contábil": "Finanças e Contábil",
"Gráfica": "Gráfica",
"Importação": "Importação",
"Indústria (Outros/Diversos)": "Indústria (Outros/Diversos)",
"Lazer": "Lazer",
"Limpeza": "Limpeza",
"Logística": "Logística",
"Manutenção": "Manutenção",
"Marketing": "Marketing",
"Metalúrgica": "Metalúrgica",
"Química": "Química",
"Saneantes": "Saneantes",
"Saúde": "Saúde",
"Seguros": "Seguros",
"Serviços Gerais": "Serviços Gerais",
"Serviços Públicos": "Serviços Públicos",
"Setor Imobiliário": "Setor Imobiliário",
"Setor Público Estadual": "Setor Público Estadual",
"Setor Público Federal": "Setor Público Federal",
"Setor Público Municipal": "Setor Público Municipal",
"Tecnologia": "Tecnologia",
"Terceiro Setor (ONG)": "Terceiro Setor (ONG)",
"Transporte": "Transporte",
"Turismo": "Turismo",
"Varejo": "Varejo",
"Vestuário": "Vestuário",
"Outro": "Outro",
  };
  let options: any = [];
  Object.keys(data).map((k) => {
    options.push({ value: k, label: data[k] });
  });

  return options;
}
