import React from 'react';

interface LogoProps {
    logotipo: boolean;
}

const Logo: React.FC<any> = (props) => {
    let logotipo: boolean = typeof props.logotipo !== 'undefined'
      ? props.logotipo : true;
    const classes = props.className || '';
    return (
        logotipo
            ? (<img src="./assets/5rindex.svg" className={`logo-comp ${classes}`} alt="5R INDEX"/>)
            : (<img src="./assets/5rindex-arco.svg" className={`logo-comp ${classes}`} alt="5R INDEX"/>)
    );
};

export default Logo;
