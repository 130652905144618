import  {AxiosRequestConfig, AxiosResponse} from 'axios';
import {http} from './api';

const fakeMode: boolean = typeof process.env.REACT_APP_API_FAKE_API === 'string'
  && process.env.REACT_APP_API_FAKE_API === 'true';

/**
 * Set header with UID Middleware to every request
 */
export function axiosAuthMiddleware() {
  return (store: any) => (next: any) => (action: any) => {
    const {auth} = store.getState().firebase;
    if (auth.isLoaded || auth.uid) {
      // console.log('auth', auth);
      http.defaults.headers.common['X-5RINDEX-UID'] = `${auth.uid}`;
    } else {
      http.defaults.headers.common['X-5RINDEX-UID'] = '';
    }
    return next(action);
  };
}


http.interceptors.request.use((config: AxiosRequestConfig) => {
  if (fakeMode) {
    let url = config.url;
    let method = config.method;
    let reqData = config.data;
    config = fakeResponse(config, url, method, reqData);
  }

  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

http.interceptors.response.use((response: AxiosResponse) => {
  // Do something with response data
  if (fakeMode) {
    response.status = 200;
    response.statusText = 'FAKE';
  }

  return response;
}, (error) => {
  // Do something with response error

  // You can even test for a response code
  // and try a new request before rejecting the promise
  // if (error.response.status === 401) {
  //   const requestConfig = error.config;
  //   return axios(requestConfig);
  // }
  return Promise.reject(error);
});

function fakeResponse(config: AxiosRequestConfig, url: string | undefined, method: string = 'post', reqdata: any = {}): AxiosRequestConfig {

  config.validateStatus = (status) => {
    return status < 1000; // default
  };

  if (url === '/code/check') {
    config.transformResponse = [(data) => routeCodeCheck(reqdata)];
  } else if (url === '/code/validate') {
    config.transformResponse = [(data) => routeCodeValidate(reqdata)];
  }

  return config;
}


function routeCodeCheck(req: any) {
  return {valid: false, uid: req.uid, _fake: true};
}

function routeCodeValidate(req: any) {
  let isValid: boolean = req.code.indexOf('err') === -1;
  return {valid: isValid, code: req.code, uid: req.uid, _fake: true};
}
