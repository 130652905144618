import React, {useMemo, useState} from 'react';
import {IonItem, IonLabel} from '@ionic/react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import { AssessmentItemProps} from '../../types/Assessments.d';
import {AssessmentPresenter} from './AssessmentPresenter';
import ColorBar from './ColorBar';
import AlertStartEvaluation from './AlertStartEvaluation';
import {startAssessment} from '../../store/actions/assessmentActions';
import useAuth from '../User/hooks/useAuth';
import {UserPresenter} from '../User/UserPresenter';

const AssessmentItem = ({assessment}: AssessmentItemProps) => {

  const history = useHistory();
  const disp = useDispatch();
  const assessmentPres = new AssessmentPresenter(assessment);
  const [start, setStart] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLogged, profile, auth] = useAuth();
  const user = new UserPresenter(profile);

  const memoColorbar = useMemo(() => {
    return <RenderColorBar assessment={assessment} />
  }, [assessment]);

  function onClick(assessment: AssessmentPresenter) {
    if (assessment.isFinished()) {
      history.push(`/score/${assessment.id}`);
    } else if (assessment.isWaiting()) {
      setStart(true);
    } else {
      history.push(`/axes/${assessment.id}`);
    }
  }

  function onStart(assessment: AssessmentPresenter) {
    disp(startAssessment(assessment.id));
    history.push(`/axes/${assessment.id}`)
  }

  function RenderColorBar(props: any){
    const assessmentPres = new AssessmentPresenter(props.assessment);
    return <ColorBar score={assessmentPres.getAxesPercent()} />
  }

  return (
    <IonItem onClick={() => onClick(assessmentPres)} className="rindex-item ion-no-padding" lines="none" detail={false}>
      <AlertStartEvaluation
        isOpen={start}
        onStart={() => onStart(assessmentPres)}
        onDismiss={() => {
          setStart(false);
        }}/>
      <IonLabel>
        <h2 className="label">{assessmentPres.entity.name}</h2>
        {assessmentPres.isInProgress() && memoColorbar}
        {user.isAdmin() && <div className="note">Por {assessmentPres.auditor.name}</div>}
      </IonLabel>
      {assessmentPres.isWaiting() && (<div slot="end" className="note">não iniciada</div>)}
      {assessmentPres.isFinished() && (<>
          <div slot="end" className="note">{assessmentPres.finalScore()}</div>
          <div slot="end" className="note">{assessmentPres.finalConcept()}</div>
        </>
      )}

    </IonItem>
  );
};

export default AssessmentItem;
