import {
  IonCard,
  IonCardContent,
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import "./ScoreDetails.scss";
import NavBar from "../../components/NavBar";
import useAssessment from "../../components/Assessment/hooks/useAssesment";
import { AssessmentPresenter } from "../../components/Assessment/AssessmentPresenter";
import PageHeader from "../../components/Assessment/PageHeader";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { RootReducerType } from "../../types/Reducers.d";
import Spinner from "../../components/UI/Spinner/Spinner";
import { Assessment, AXIS } from "../../types/Assessments.d";
import QuestionsList from "../../components/Assessment/QuestionsList";
import Api from "../../support/api";

interface ScoreDetailsProps {}

const ScoreDetails = ({
  match: {
    params: { assessmentId },
  },
}: ScoreDetailsProps & RouteComponentProps<{ assessmentId: string }>) => {
  const assessment = useAssessment(assessmentId, false);
  const assessmentPre = new AssessmentPresenter(assessment);

  // if(!assessment){
  //   return  <Spinner />;
  // }

  function RenderList({ assessment }: { assessment: AssessmentPresenter }) {
    // useFirestoreConnect(`questions_${assessment.version}`);
    // const questions = useSelector((state: RootReducerType) => state.firestore.data[`questions_${assessment.version}`]);
    //

    // console.log(questions);

    return (
      <>
        <QuestionsList
          mode="detail"
          assessment={assessmentPre}
          axis={`reconhecimento`}
        />
      </>
    );
  }

  return (
    <IonPage>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle>Detalhamento</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div data-comp-scoredetails={true} className="content">
          <div className="ion-padding-horizontal">
            <PageHeader assessment={assessmentPre} />
          </div>
          <IonCard>
            <IonCardContent className="ion-no-padding">
              {assessmentPre &&
                Object.keys(AXIS).map((axe) => {
                  return (
                    <QuestionsList
                      key={axe}
                      mode="detail"
                      assessment={assessmentPre}
                      axis={axe}
                    />
                  );
                })}
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
      <IonFooter>
        <NavBar />
      </IonFooter>
    </IonPage>
  );
};

export default ScoreDetails;
