import React from 'react';
import {useHistory, useRouteMatch} from 'react-router';
import {IonAlert} from '@ionic/react';
import {useDispatch} from 'react-redux';
import {AssessmentScore} from '../../../types/Assessments.d';
import {consolidateAssessment} from '../../../store/actions/assessmentActions';

export function AlertConsolidate(props: { isOpen: boolean, onDismiss: () => void }) {

  const disp = useDispatch();
  const history = useHistory();
  const {params: {assessmentId}} = useRouteMatch<{ assessmentId: string }>();

  function didPresent() {

  }

  function doConsolidation({note}:{note: string}) {
    disp(consolidateAssessment(assessmentId, {
      notes: note
    },
      (success: boolean, score?: AssessmentScore, err?: Error) => {

        if (success) {
          history.push(`/score/${assessmentId}`);
        } else {
          // TODO show error
          alert(err);
        }

      }));
  }

  return (
    <IonAlert
      cssClass="rindex-consolidate-alert"
      backdropDismiss={false}
      isOpen={props.isOpen}
      onDidPresent={didPresent}
      onDidDismiss={() => props.onDismiss()}
      header="Consolidar"
      subHeader="Ao consolidar a avaliação não será possível editar as respostas."
      message={`<p>Deseja continuar?</p>`}
      inputs={[
        {
          name: "note",
          type: "textarea",
          value: '',
          id: 'note-textarea',
          placeholder: "Deixe uma nota...",
        },
      ]}
      buttons={[
        {
          text: 'CANCELAR',
          cssClass: 'secondary',
          role: 'cancel'
        }, {
          text: 'CONSOLIDAR',
          role: 'OK',
          cssClass: 'primary',
          handler: doConsolidation
        },
      ]}
    />
  );
}
