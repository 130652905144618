import React from 'react';
import { IonAlert } from '@ionic/react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';


interface AlertEntityCreatedProps {

  isOpen: boolean;
  onDismiss(): void;
}

export default function AlertEntityCreated(props: AlertEntityCreatedProps){

  const router = useHistory();
  function handleIniciar(){
    router.push(`/assessments`)
  }

  return (
    <IonAlert
      cssClass=""
      backdropDismiss={true}
      isOpen={props.isOpen}
      // onDidPresent={didPresent}
      onDidDismiss={() => props?.onDismiss()}
      header={'Entidade criada'}
      // subHeader={`Próximo passo:`}
      message={`Agora você já pode iniciar a avaliação. Em "Avaliações", selecione a entidade para iniciar."`}

      buttons={[
        {
          text: 'VOLTAR',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'INICIAR',
          role: 'OK',
          cssClass: 'primary',
          handler: handleIniciar
        },
      ]}
    />
  );
}
