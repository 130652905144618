import React, { useRef } from "react";
import { IonCard, IonCardContent, IonSlide, IonSlides } from "@ionic/react";
import { AssessmentPresenter } from "../AssessmentPresenter";
import SingleQuestion from "./SingleQuestion";
import { Question, ResponseAxis } from "../../../types/Assessments.d";
import { useMemo } from "react";
import QuestionsNavContext from "../../../pages/AssessmentsQuestions/QuestionsNavContext";

interface QuestionsSliderProps {
  questions: any;
  assessment: AssessmentPresenter;
  axis: string;
  start: string;
  assessmentId: string;
}

const QuestionsSlider = ({
  questions,
  assessment,
  axis,
  start,
  assessmentId,
}: QuestionsSliderProps) => {
  const slideOpts = {
    initialSlide: parseInt(start) || 0,
    speed: 400,
    loop: true,
  };
  const axisQuestions = questions[axis];
  const axisAnswers: ResponseAxis[] = Object.values(assessment.responses[axis]);
  // const slideRef = useRef<any>(null);

  const SlidesMemoised = useMemo(() => {
    return (
      <>
        {Object.keys(axisQuestions)
          .sort()
          .map((key: any, i: number) => {
            let question: Question = axisQuestions[key];
            let answer = axisAnswers.find(
              (ans) => ans.code === question.code
            ) || {
              code: "",
              group: "",
              answer: null,
              notes: "",
            };
            return (
              <IonSlide key={i}>
                <SingleQuestion
                  assessmentId={assessmentId}
                  question={question}
                  answer={answer}
                  i={i}
                  axis={axis}
                />
              </IonSlide>
            );
          })}
      </>
    );
  }, [axisQuestions]);

  return (
    <QuestionsNavContext.Consumer>
      {({slidesRef}) => (
        <IonCard className="card__first">
          <IonCardContent>
            <IonSlides
              ref={slidesRef}
              scrollbar={true}
              pager={false}
              options={slideOpts}
            >
              {SlidesMemoised}
            </IonSlides>
          </IonCardContent>
        </IonCard>
      )}
    </QuestionsNavContext.Consumer>
  );
};

export default QuestionsSlider;
