import React, { useMemo } from 'react';
import {IonCol, IonGrid, IonRow} from '@ionic/react';
import {AssessmentPresenter} from './AssessmentPresenter';
import './PageHeader.scss';
import StatusRing from './StatusRing';
import { useSelector } from 'react-redux';
import { RootReducerType } from '../../types/Reducers.d';
import { progressFinished } from '../../support/helpers';
import { useRouteMatch } from 'react-router';

interface PageheaderProps {
  assessment: AssessmentPresenter
}

const PageHeader = (props: PageheaderProps) => {
  const {assessment} = props;
  // const router = useRouteMatch()
  // const isReadyToFinish: boolean = assessment.isReadyToFinish();
  const progress = useSelector((status: RootReducerType) => status.progress[assessment.id]);

    const finished = useMemo(()=>{
      if(progress?.score){
        return progressFinished(progress?.score)
      }
      return false;
    }, [progress])

  return (
    <div data-comp-pageheader={true}>
      <IonGrid>
        <IonRow className="ion-justify-content-center">
          <IonCol style={{flexGrow: 2}}>
            <h2>{assessment?.entity.name}</h2>
            <p className="note">
              Início em {assessment.iniDateOnly()} às {assessment.iniHourOnly()} <br/>
              Por {assessment.auditor.name}
            </p>
          </IonCol>
          {(progress?.score && finished && !assessment.isFinished()) &&
          <IonCol style={{flexGrow: 0, padding: '20px 0', display:'flex'}}>
          <StatusRing
                  finished={finished}
                  score={progress?.score}/>
          </IonCol>}

          <IonCol>
            {assessment.isFinished() ? (
              <div className="box-note finished">
                <div>{assessment.finalConcept()}</div>
                <div>{assessment.finalScore()}</div>
              </div>
            ) : (
              <div className="box-note">
                <div>Parcial</div>
                <div>{assessment.finalScore(true)}</div>
              </div>
            )}

          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default PageHeader;
