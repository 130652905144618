import {Dispatch} from 'redux';
import Api from '../../support/api';
import {AxiosResponse} from 'axios';

export const CONFIG_ACTION_TYPE = {
  LOAD: 'CONFIG_LOAD',
};

export const loadConfig = () => {
  return async (dispatch: Dispatch, getState: any, getFirebase: any) => {

    // let configs: any = {};

    const apiConfigs: Promise<AxiosResponse<any>> = Api.configs();
    apiConfigs.then((resp) => {
      dispatch({type: CONFIG_ACTION_TYPE.LOAD, payload: resp.data});
    });

    /*
    @deprecated Using API to provide global configurations
     */
    // const fbConfigs = getFirebase().firestore().collection('configs');
    //
    // fbConfigs.get()
    //   .then((snapShot: any) => {
    //     snapShot.forEach((c: any) => {
    //       configs[c.id] = c.data();
    //     });
    //   })
    //   .catch((err: any) => {
    //     console.log(err);
    //   });


  };
};
