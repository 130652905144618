import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import React, { useState, useRef, useCallback } from "react";
import Loading from "../Loading";
import { IonAlert, IonToast } from "@ionic/react";
import { validateCode } from "../../../store/actions/authActions";


const AlertCodeFnc = (props: any) => {
  const disp = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({show: false, msg: '', variation: 'secondary'});
  // const [toastMsg, setToastMsg] = useState("");
  // const [payload, setPayload] = useState({teste: 'ok'});
  const payload = useRef(null);

  const handleCodeValidation = (values: { code: string }) => {

    setLoading(true);

      disp(
        validateCode(values.code, (pl: any) => {
          setLoading(false);
          payload.current = pl;
          if (pl.success) {

            // setToast({show: true, msg: pl.msg, variation: 'primary'});
            // props.showWelcome(payload);
            return true;
          } else {
            setToast({show: true, msg: pl.msg, variation: 'secondary'});
          }
        })
      );
      return true;
  };


  function didPresent() {
    let input = document.getElementById("my-code-input");
    if (input) input.focus();
  }

  return (
    <>
      <IonToast
        isOpen={toast.show}
        onDidDismiss={() => setToast({...toast, show: false})}
        message={toast.msg}
        position="top"
        color={toast.variation}
        duration={3000}
      />
      <Loading isOpen={loading} message="Validando..." />
      <IonAlert
        cssClass="rindex-login-alert"
        backdropDismiss={false}
        isOpen={props.isOpen}
        onDidPresent={didPresent}
        onWillDismiss={() => {
          props.onDismiss(payload.current)
        }}
        // header={'Alert'}
        // subHeader={``}
        message={`<img src="/assets/logo-oci.svg" alt="OCI" />
        <p class="subtit">AUDITORIA FUNCIONAL DA COMUNICAÇÃO ORGANIZACIONAL</p>`}
        inputs={[
          {
            name: "code",
            type: "text",
            id: "my-code-input",
            placeholder: "Código do credenciado",
          },
        ]}
        buttons={[
          {
            text: "AJUDA",
            cssClass: "secondary",
            handler: () => {
              history.push("/help");
            },
          },
          {
            text: "ENTRAR",
            role: "OK",
            cssClass: "primary",
            handler: handleCodeValidation,
          },
        ]}
      />
    </>
  );
}


export const AlertCode = AlertCodeFnc
