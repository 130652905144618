import {useFirestoreConnect} from 'react-redux-firebase';
import {useSelector} from 'react-redux';
import {RootReducerType} from '../../../types/Reducers.d';

function useEntity(entityId: string) {
  useFirestoreConnect([{
    collection: 'entities',
    storeAs: 'current_entity',
    doc: entityId
  }]);

  return useSelector((state: RootReducerType) => state.firestore.data.current_entity);
}

export default useEntity;
