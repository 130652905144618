import {Dispatch} from 'redux';
import {Entity} from '../../types/Entities.d';
import {Assessment, AssessmentScore, Status} from '../../types/Assessments.d';
import moment from 'moment';
import {DATE_FORMAT, numToQID} from '../../support/helpers';
import {updateEntity} from './entityActions';
import {AssessmentPresenter} from '../../components/Assessment/AssessmentPresenter';
import Api from '../../support/api';
import { AxiosError, AxiosResponse } from 'axios';


export const currentVersion: string = process.env.REACT_APP_CURRENT_VERSION || 'v2';

export const ASSESSMENT_ACTION_TYPE = {
  CREATED: 'ASSESSMENT_CREATED',
  CREATED_ERR: 'ASSESSMENT_CREATED_ERROR',
  UPDATED: 'ASSESSMENT_UPDATED',
  UPDATED_ERR: 'ASSESSMENT_UPDATED_ERROR',
  ANSWER_UPDATED: 'ANSWER_UPDATED',
  UPDATE_SCORE: 'UPDATE_SCORE',
};

export const fetchAssessment = (
  assessmentId: string,
  callback: (assessment: Assessment) => void
) => {
  return async (dispatch: Dispatch, getState: any, getFirebase: any) => {

    /** @param doc: DocumentSnapshot */
    const doc = await getFirebase().firestore()
      .collection('assessments')
      .doc(assessmentId)
      .get();
    callback(doc.data());

  };
};

/**
 *
 * @param entity
 */
export const createAssessment = (entity: Entity) => {
  return async (dispatch: Dispatch, getState: any, getFirebase: any) => {

    // create doc
    let doc = {
      id: '',
      entity: {id: entity.id, name: entity.name},
      iniDate: moment().format(DATE_FORMAT),
      endDate: null,
      auditor: {
        id: getState().firebase.auth.uid,
        name: getState().firebase.auth.displayName
      },
      status: Status.WAITING,
      notes: '',
      version: currentVersion,
      responses: {}
    };

    // clone answers structure
    doc.responses = await responsesStructure(getFirebase(), currentVersion);
    // console.log(doc);

    // save assessment
    const assessment = await saveAssessment(getFirebase(), doc);

    // update entity assessmentId: []
    let ids: string[] = Object.assign([], entity?.assessmentId) || [];
    ids.push(assessment.id)

    try {
      await updateEntity(getFirebase(), entity.id, {
        assessmentId: ids
      });
      dispatch({type: ASSESSMENT_ACTION_TYPE.CREATED, payload: assessment});

    } catch (err) {
      dispatch({type: ASSESSMENT_ACTION_TYPE.CREATED_ERR, payload: err});
    }

  };
};


export const rebuildResponses = (assessmentId: string, version: string = 'v1') => {
  return async (dispatch: Dispatch, getState: any, getFirebase: any) => {

    // clone answers structure
    const responses = await responsesStructure(getFirebase(), version);

    await getFirebase().firestore()
      .collection('assessments')
      .doc(assessmentId)
      .update({responses})
    ;
  };
};

/**
 * Prepare assessment to start
 * @param assessmentId
 */
export const startAssessment = (assessmentId: string) => {
  return async (dispatch: Dispatch, getState: any, getFirebase: any) => {

    // get
    const responseAxis = await responsesStructure(getFirebase(), currentVersion);

    const axes: string[] = Object.keys(responseAxis);
    const score: any = {final: {points: 0, concept: ''}, url: ''};
    axes.forEach((axis: string) => {
      score[axis] = 0; // init values
    });

    // change status, update iniDate
    updateAssessment(getFirebase(), assessmentId, {
      status: Status.IN_PROGRESS,
      iniDate: moment().format(DATE_FORMAT),
      score
    });

    // notify API > send email admin
    Api.log({type: 'assessment/init', assessment_id: assessmentId});

  };
};

/**
 * Update a single value of an answer
 * @param assessmentId
 * @param axis
 * @param i
 * @param val
 * @param callback
 */
export const updateAnswer = (assessmentId: string, axis: string, i: number, val: number, callback: (success: boolean, err?: Error | undefined) => void) => {
  return async (dispatch: Dispatch, getState: any, getFirebase: any) => {

    let update: any = {};
    update[`responses.${axis}.${numToQID(i)}.answer`] = val;

    const collection = getFirebase().firestore().collection('assessments');
    await collection.doc(assessmentId).update(update);
    const doc = await collection.doc(assessmentId).get();

    dispatch({
      type: ASSESSMENT_ACTION_TYPE.ANSWER_UPDATED,
      payload: doc.data()
    });
    callback(true);
  };
};

/**
 * Update a note for an answer
 * @param assessmentId
 * @param axis
 * @param i
 * @param notes
 * @param callback
 */
export const updateAnswerNote = (assessmentId: string, axis: string, i: number, notes: string, callback: (success: boolean, err?: Error | undefined) => void) => {
  return async (dispatch: Dispatch, getState: any, getFirebase: any) => {

    let update: any = {};
    update[`responses.${axis}.${numToQID(i)}.notes`] = notes;

    // console.log(update);

    const collection = getFirebase().firestore().collection('assessments');
    await collection.doc(assessmentId).update(update);

    callback(true);
  };
};

/**
 * Count final score, close assessment status
 * @param assessmentId
 * @param callback
 */
export const consolidateAssessment = (
  assessmentId: string,
  args: {notes?: string},
  callback: (success: boolean, score?: AssessmentScore, err?: Error) => void
) => {
  return async (dispatch: Dispatch, getState: any, getFirebase: any) => {

    const collection = getFirebase().firestore().collection('assessments');
    // get responses
    const doc = await collection.doc(assessmentId).get();

    // count
    const assessment = new AssessmentPresenter(doc.data());
    const axes: any = assessment.getAxesScore();
    const concept: string[] = assessment.countFinalConcept();
    const score: number = assessment.countFinalScore();

    let _axes: any = {};
    Object.keys(axes).forEach((axis) => {
      _axes[axis] = parseFloat(axes[axis].score.toFixed(2));
    });

    // validate final
    let scoreObj: AssessmentScore = {
      ..._axes,
      final: {
        points: score,
        concept: concept.join('')
      },
      url: `${Api.getWebUrl()}/score/${assessmentId}`
    };

    await collection.doc(assessmentId).update({
      score: scoreObj,
      status: Status.FINISHED,
      endDate: moment().format(DATE_FORMAT),
      notes: args?.notes
    });

    const doc2 = await collection.doc(assessmentId).get();
    Api.registerAssessment(doc2.data());

    Api.log({
      type: 'assessment/end',
      entity_id: assessment.entity.id,
      entity_name: assessment.entity.name,
      assessment_id: assessmentId,
      score_points: scoreObj.final.points,
      score_concept: scoreObj.final.concept,
      data: {}
    });

    callback(true, scoreObj);

  };
};

/**
 * Send assessment data to API
 * @param assessmentId
 * @returns
 */
export const syncAssessment = (assessmentId: string, callback: (response: AxiosResponse<any>, code: 200 | number)=> void) => {
  return async (dispatch: Dispatch, getState: any, getFirebase: any) => {

    const collection = getFirebase().firestore().collection('assessments');
    // get responses
    const doc = await collection.doc(assessmentId).get();

    Api.registerAssessment(doc.data())
    .then(response => {
      callback(response, 200);
    })
    .catch((err: AxiosError) => {
      // console.log(err.response);
      callback(err.response as AxiosResponse, err?.response?.status || 400);
    })
  }
}

function saveAssessment(firebase: any, assessment: any): Promise<any> {

  return new Promise((resolve, reject) => {
    /** @var id: DocumentReference   */
    const newDoc: any = firebase.firestore().collection('assessments')
      .doc();

    assessment.id = newDoc.id;
    firebase.firestore().collection('assessments')
      .doc(newDoc.id)
      .set(assessment).then(() => {
      resolve(assessment);
    }).catch((err: any) => {
      reject(err);
    });
  });
}

/**
 * return answer structure based on a question version
 * @param firebase
 * @param version
 */
function responsesStructure(firebase: any, version: string = 'v1'): Promise<any> {

  return firebase.firestore()
    .collection(`questions_${version}`)
    .get()
    .then((snapShop: any) => {

      let responseAxis: any = {};
      snapShop.forEach((axis: any) => {
        let structure: any = {};
        let data: any = axis.data();
        Object.keys(data).sort().forEach((key: any, i: number) => {
          let answ:any = data[key];
          structure[numToQID(i)] = {
            code: answ.code,
            group: answ.group,
            answer: null,
            notes: ''
          };
        });
        responseAxis[axis.id] = structure;
      });

      return responseAxis;
    });
}


/**
 * Update assessment
 * @param firebase
 * @param assessmentId
 * @param data
 */
export function updateAssessment(firebase: any, assessmentId: string, data: any): Promise<any> {

  return firebase.firestore()
    .collection('assessments')
    .doc(assessmentId)
    .update(data);
}
